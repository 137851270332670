import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const show = async (credentials, workspaceId, modelId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/models/${modelId}`, credentials)
  return response
}

export const list = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/models`, credentials)
  return response
}

export const create = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/models`, data, credentials)
  return response
}

export const update = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/models/${data.id}`, data, credentials)
  return response
}

export const addData = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/models/${data.id}/data`, data.formData, credentials)
  return response
}

export const train = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/models/${data.id}/train`, data, credentials)
  return response
}

export const predict = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/models/${data.id}/predict`, data, credentials)
  return response
}

export const destroy = async (credentials, workspaceId, data) => {
  const response = await axios.delete(`${HYDRA_API}/workspaces/${workspaceId}/models/${data.id}`, credentials)
  return response
}

