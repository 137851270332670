import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ModelBasicInfo } from './ModelBasicInfo'
import { TargetFieldSelector } from './TargetFieldSelector'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditableLabel } from './EditableLabel'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { AutomationSelector } from '../automations/AutomationSelector'
import { ModelSummary } from './ModelSummary'
import { ModelTemplates } from '../models/ModelTemplates'
import { ConfirmationDialog } from './ConfirmationDialog'
import Session from './Session'
import { addNewModel, updateModel, deleteModel } from '../models/modelsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'

import { getCredentials } from '../connections/connectionsApi';
import { show } from '../models/modelsApi';

import { templates } from '../models/templateConfigs'
import { Notification } from '../utilities/Notification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100vh',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 300,
    paddingTop: '20vh',
    backgroundColor: '#FCFCFC',
    color: 'black',
  },
  wrapper: {
    alignItems: 'flex-start',
    marginLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,

  },
  indicator: {
    backgroundColor: '#4B6BD6',
  },
  modelBuilderContent: {
    padding: 80,
    overflowY: 'hidden',
    height: '100vh',
  },
  modelBuilderTemplates: {
    padding: theme.spacing(5),
    height: '100vh',
    overflowY: 'scroll',
    width: '100%',
  },
  exitFab: {
    position: 'fixed',
    top: theme.spacing(2),
    left: theme.spacing(3),
    boxShadow: 'unset',
    backgroundColor: 'black',
  },
  appBar: {
    backgroundColor: '#4B6BD6',
    boxShadow: 'unset',
    zIndex: 1,
  },
  saveButton: {
    color: 'white',
    fontWeight: 600,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  linearProgressBar: {
    zIndex: 9,
    minWidth: '100%',
    position: 'absolute',
  },
}));

/**
 * Model builder wizard component
 * 
 * @param {*} props 
 */
export const ModelBuilderWizard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(0);

  const modelStatus = useSelector((state) => state.models.status)

  const [session, setSession] = useState(Session.getSession())

  const [connectionToken, setConnectionToken] = useState('');

  //field states
  const [id, setId] = useState(useParams().modelId)

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [modelEditable, setModelEditable] = useState(false)
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success'})

  const [triggerConfig, setTriggerConfig] = useState([])
  const [minConfidence, setMinConfidence] = useState(40)
  const [labelConfig, setLabelConfig] = useState([])
  const [modelName, setModelName] = useState('Give your model a name')
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [automationStartFields, setAutomationStartFields] = useState([]);


  const [selectedTemplate, setSelectedTemplate] = useState('')
  const handleTemplateSelection = (templateId) => {
    setSelectedTemplate(templateId);
  }

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const navigateModelsList = () => {
    history.push(`/workspaces/${session.workspaceId}/models/`);
  }

  const promptDelete = async () => {
    if (modelStatus != 'loading') {
      setShowDeleteConfirm(true);
    }
  }

  const runModelDelete = async () => {
    if (modelStatus != 'loading') {
      try {
        if (id) {
          const modelDelete = await dispatch(deleteModel({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: {
              id: id,
            }
          }))
          unwrapResult(modelDelete)
        }
        setShowDeleteConfirm(false)
        history.push(`/workspaces/${session.workspaceId}/models`);
      } catch (e) {

      }
    }
  }

  const saveModel = async () => {
    if (modelStatus != 'loading') {
      try {
        if (id) {
          const modelUpdate = await dispatch(updateModel({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: {
              id: id,
              name: modelName,
              description: modelName,
              templateRef: templates[selectedTemplate].name,
              clfType: templates[selectedTemplate].clfType,
              source: templates[selectedTemplate].source,
              labels: labelConfig,
              minConfidence: minConfidence,
              triggerConfig: { filter: triggerConfig },
              automationConfig: { automationId: selectedAutomation, startFields: automationStartFields }
            },
          }))
          unwrapResult(modelUpdate)
          setNotificationConfig({show: true, notice: 'Model saved', severity: 'success'})
        } else {
          const modelSave = await dispatch(addNewModel({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: {
              name: modelName,
              description: modelName,
              templateRef: templates[selectedTemplate].name,
              clfType: templates[selectedTemplate].clfType,
              source: templates[selectedTemplate].source,
              labels: labelConfig,
              minConfidence: minConfidence,
              triggerConfig: { filter: triggerConfig },
              automationConfig: { automationId: selectedAutomation, startFields: automationStartFields }
            },
          }))
          const model = unwrapResult(modelSave)
          setId(model.id);
          history.push(`/workspaces/${session.workspaceId}/models/${model.id}`);
          setNotificationConfig({show: true, notice: 'Model created', severity: 'success'})
        }
      } catch (err) {
          setNotificationConfig({show: true, notice: 'Complete all the steps and try again', severity: 'error'})
      }
    }
  }

  useEffect(() => {
    if (id) {
      show(session.authHeader, session.workspaceId, id).then(rs => {
        ReactDOM.unstable_batchedUpdates(() => {
          setModelName(rs.data.name);
          setSelectedTemplate(templates.find(e => (e.name === rs.data.templateRef)).id);
          setLabelConfig(rs.data.labels || []);
          setTriggerConfig(rs.data.triggerConfig?.filter || []);
          setMinConfidence(rs.data.minConfidence || 40);
          setSelectedAutomation(rs.data.automationConfig?.automationId || '');
          setAutomationStartFields(rs.data.automationConfig?.startFields || []);
        })
      })
    }
  }, [id, session])

  useEffect(() => {
    if (templates[selectedTemplate]?.source !== 'api' && templates[selectedTemplate]?.source !== undefined) {
      console.log(`looking for connection token => ${templates[selectedTemplate]?.source}`);
      getCredentials(session.authHeader, session.workspaceId, templates[selectedTemplate]?.source).then(rs => {
        rs ? setConnectionToken(rs.data?.accessToken) : setConnectionToken(rs);
      });
    } else {
      setConnectionToken('internal');
    }
  }, [selectedTemplate, session])

  useEffect( () => {
   (selectedTemplate !== '' && connectionToken) ? setModelEditable(true) : setModelEditable(false)
  }, [selectedTemplate, connectionToken])

  const startFieldParamOptions = () => {
    const lblArray = labelConfig.map(e=> ({[`{${e.label}}`]: e.name}));
    return ((lblArray.length > 0) ? lblArray.reduce((a, c) => ({...a, ...c})) : {})
  }

  return (
    <div className={classes.root}>
      { modelStatus === 'loading' ? <LinearProgress className={classes.linearProgressBar} /> : ''}
      <CssBaseline />
      <AppBar position="fixed"
        className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton onClick={navigateModelsList} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <ArrowBackIcon />
          </IconButton>
          <EditableLabel label={modelName} setLabel={setModelName} />
          <Button className={classes.saveButton} onClick={promptDelete}> Delete </Button>
          <Button className={classes.saveButton} onClick={saveModel}> Save </Button>
        </Toolbar>
        <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
      </AppBar>
      <div className={classes.toolbar} />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
      >
        <Tab disableRipple={true} classes={{ wrapper: classes.wrapper }} label="Step 1: Template" {...a11yProps(0)} />
        <Tab disabled={(selectedTemplate === '')} disableRipple={true} classes={{ wrapper: classes.wrapper }} label="Step 2: Basics" {...a11yProps(1)} />
        <Tab disabled={!modelEditable} disableRipple={true} classes={{ wrapper: classes.wrapper }} label="Step 3: Predictions" {...a11yProps(2)} />
        <Tab disabled={!modelEditable} disableRipple={true} classes={{ wrapper: classes.wrapper }} label="Step 4: Automations" {...a11yProps(3)} />
        <Tab disabled={!modelEditable} disableRipple={true} classes={{ wrapper: classes.wrapper }} label="Step 5: Summary" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.modelBuilderTemplates}>
        <ModelTemplates templates={templates} handleSelection={handleTemplateSelection} selected={selectedTemplate} modelId={id} />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.modelBuilderContent}>
        <ModelBasicInfo minConfidence={minConfidence} setMinConfidence={setMinConfidence} triggerConfig={triggerConfig} setTriggerConfig={setTriggerConfig} accessToken={connectionToken} source={templates[selectedTemplate]?.source} templateName={templates[selectedTemplate]?.name} workspaceId={session.workspaceId}/>
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.modelBuilderContent}>
        <TargetFieldSelector labelConfig={ (labelConfig.length > 0) ? labelConfig : templates[selectedTemplate]?.labelConfig} setLabelConfig={setLabelConfig} accessToken={connectionToken} source={templates[selectedTemplate]?.source} templateName={templates[selectedTemplate]?.name}/>
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.modelBuilderContent}>
        <AutomationSelector selectedAutomation={selectedAutomation} setSelectedAutomation={setSelectedAutomation} automationStartFields={automationStartFields} setAutomationStartFields={setAutomationStartFields} paramOptions={startFieldParamOptions()} />
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.modelBuilderContent}>
        <ModelSummary summaryText={ templates[selectedTemplate]?.summaryText} modelName={modelName} modelSource={ templates[selectedTemplate]?.source } workspaceId={session.workspaceId} modelId={id}/>
      </TabPanel>
      <ConfirmationDialog show={showDeleteConfirm} dialogTitle={'Are you sure'} dialogText={'This can not be undone'} handleConfirm={runModelDelete} handleCancel={() => setShowDeleteConfirm(false)} />
    </div>
  );
}
