import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useSelector } from 'react-redux'
import { Notification } from '../utilities/Notification'
import { useHistory } from 'react-router-dom'
import Session from './Session'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: '100%',
  },
  wrapper: {
    alignItems: 'flex-start',
    marginLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,

  },
  indicator: {
    backgroundColor: '#4B6BD6',
  },
  modelBuilderContent: {
    padding: 80,
    overflowY: 'hidden',
    height: '100vh',
  },
  modelBuilderTemplates: {
    padding: theme.spacing(5),
    height: '100vh',
    overflowY: 'scroll',
  },
  exitFab: {
    position: 'fixed',
    top: theme.spacing(2),
    left: theme.spacing(3),
    boxShadow: 'unset',
    backgroundColor: 'black',
  },
  appBar: {
    backgroundColor: '#4B6BD6',
    boxShadow: 'unset',
    zIndex: 1,
  },
  saveButton: {
    color: 'white',
    fontWeight: 600,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  linearProgressBar: {
    zIndex: 9,
    minWidth: '100%',
    position: 'absolute',
  },
  modelTitle: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 500,
  }
}));

export const AppSubLayout = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [session, setSession] = useState(Session.getSession())
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success'})
  const modelStatus = useSelector((state) => state.models.status)

  const navigateModelsList = () => {
    history.push(`/workspaces/${session.workspaceId}/models/`);
  }


  return (
    <div className={classes.root}>
      { modelStatus === 'loading' ? <LinearProgress className={classes.linearProgressBar} /> : ''}
      <CssBaseline />
      <AppBar position="fixed"
        className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton onClick={navigateModelsList} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <ArrowBackIcon />
          </IconButton>
          { props.title &&
            <Box className={classes.modelTitle}>{props.title}</Box>
          }
        </Toolbar>
        <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}