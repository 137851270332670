import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { CreateConnectionButton } from '../utilities/CreateConnectionButton'
import { ActionCondition} from './ActionCondition'
import { compileDynamicOptions } from '../utilities/dynamicFieldUtils'
import { cloneDeep } from 'lodash'
import Button from '@material-ui/core/Button';
import { ConfirmationDialog } from '../utilities/ConfirmationDialog'
import { ConnectionConfig } from '../connections/ConnectionConfig'
import Session from '../utilities/Session'
import { getCredentials } from '../connections/connectionsApi';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '60%',
  },
  editorPaper: {
    padding: theme.spacing(2),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    margin: theme.spacing(2),
    backgroundColor: '#FCFCFC',
  },
  configDivider: {
    marginTop: 30,
    marginBottom: 30,
  },
  configSectionLabel: {
    marginBottom: 10,
  },
  fieldMapInput: {
    width: '100%',
  },
  inputParamField: {
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    marginBottom: theme.spacing(2),
  }
})
)

export const ActionConfig = (props) => {
  const classes = useStyles();
  const [hasConnection, setHasConnection] = useState(false);
  const [connectionConfig, setConnectionConfig] = useState({connectionType: 'apiKey', connectionLink: '' })
  const [session, setSession] = useState(Session.getSession())
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  let selectedNode = cloneDeep(props.nodes[props.selectedNode]);
  
  const updateInput = (value, inputIndex) => {
    (selectedNode.input[inputIndex]).value = value;
    let _nodes = cloneDeep(props.nodes);
    _nodes[props.selectedNode] = selectedNode;
    props.setNodes(_nodes);
  }

  const updateRunCondition = (value) => {
    const conditionTemplate = {fieldName: '', rule: '', value: ''}
    selectedNode.condition[0] = { ...conditionTemplate, ...selectedNode.condition?.[0], ...value };
    let _nodes = cloneDeep(props.nodes);
    _nodes[props.selectedNode] = selectedNode;
    props.setNodes(_nodes);  
  }

  const updateProvider = (value) => {
    selectedNode.connectionRef = value;
    let _nodes = cloneDeep(props.nodes);
    _nodes[props.selectedNode] = selectedNode;
    props.setNodes(_nodes);
  }

  const runActionStepDelete = () => {
    let updatedNodes = props.nodes.filter(node => node !== props.nodes[props.selectedNode] )
    props.setSelectedNode(0)
    props.setNodes(updatedNodes)
   
  }

  let dynamicFields = compileDynamicOptions(props.nodes, props.selectedNode);

  /*
  useEffect(() => {
    getCredentials(session.authHeader, session.workspaceId, selectedNode.connectionRef).then( rs => {
      rs ? setHasConnection(true) : setHasConnection(false);
    });
  }, [selectedNode])
  */
  
  return (
    <Box component={'span'}>
      <CssBaseline />
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Connect to the system
      </Typography>
        <ConnectionConfig provider={selectedNode.connectionRef} updateProvider={updateProvider}/>
      </Paper>
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Run only if
      </Typography>
        <ActionCondition dynamicFields={dynamicFields} updateRunCondition={updateRunCondition} runCondition={selectedNode.condition}/>
      </Paper>
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Map data
      </Typography>
      <br/>
      {selectedNode.input.map( (input, index) => (<TextField id={`${input.key}-${props.selectedNode}`} key={`${input.key}-${props.selectedNode}`} label={input.label} onChange={(e)=> updateInput(e.target.value, index)} value={input.value} classes={{root: classes.root}} className={classes.inputParamField} />) )}
      </Paper>
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Output fields
      </Typography>
      {selectedNode.output.map( out => (<Typography key={out.key}>{out.label} ({out.dataType})</Typography>) )}
      </Paper>
      <Paper className={classes.editorPaper} elevation={0}>
      <Button
          type="button"
          size="small"
          color="secondary"
          className={classes.deleteButton}
          onClick={ (_e) => setShowDeleteConfirm(true)}
        >
          Delete Action Step
          </Button>
      
      </Paper>
      <ConfirmationDialog show={showDeleteConfirm} dialogTitle={'Are you sure'} dialogText={'This can not be undone'} handleConfirm={runActionStepDelete} handleCancel={() => setShowDeleteConfirm(false)} />
    </Box>
  )

}