import React from 'react';
import { SvgIcon, makeStyles } from '@material-ui/core';
import { ReactComponent as EmailSvg } from '../../icons/email.svg';

const useStyles = makeStyles((theme) => ({
  mySvgStyle:{
    fillColor: theme.palette.primary.main
  }
}))

export const EmailIcon = () => {
  const classes = useStyles();
  return(
    <SvgIcon className={classes.mySvgStyle} viewBox="0 0 512 512">
      <EmailSvg/>
    </SvgIcon>
  )
}