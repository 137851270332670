import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import TextField from '@material-ui/core/TextField';
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

import { inviteUser, selectUserById } from '../users/usersSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  settingsSectionBox: {
    backgroundColor: 'white',
    minHeight: '10vh',
    width: '100%',
    marginBottom: 30,
  },
  memberPanel: {
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingTop: 20,
  },
  iconButtonContainer: {
    paddingTop: theme.spacing(3)
  }
}));

export const InviteTeamMember = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [session, setSession] = useState(Session.getSession())
  const userStatus = useSelector((state) => state.users.status)
  const user = useSelector( state => selectUserById(state, session.userId));

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [invitedBy, setInvitedBy] = useState(user?.firstName || '')
  const [updateFailed, setUpdateFailed] = useState(false)

  const onFirstNameChanged = (e) => setFirstName(e.target.value)
  const onLastNameChanged = (e) => setLastName(e.target.value)
  const onEmailChanged = (e) => setEmail(e.target.value)

  const canUpdate =
    [firstName, lastName, email].every(Boolean) && userStatus != 'loading'
  
  const handleSendInvitationClick = async () => {
    //handle invitation delivery here
    if(canUpdate) {
      try {
        setUpdateFailed(false)
        const userInvite = await dispatch(inviteUser({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { email, firstName, lastName, invitedBy },
        }))
        unwrapResult(userInvite);
        setFirstName('');
        setLastName('');
        setEmail('');
        props.setNotificationConfig({show: true, notice: 'Invitation sent', severity: 'success'})
      } catch (err) {
        setUpdateFailed(true)
        console.log(err);
        props.setNotificationConfig({show: true, notice: 'Something went wrong', severity: 'error'})
      }
    }
  }

  useEffect(() => {
    setInvitedBy(user?.firstName || '');
  }, [user])

  return (

    <Paper className={classes.settingsSectionBox} elevation={0} key={0}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        spacing={3}>
        <Grid item xs={12} md={1}>

        </Grid>
        <Grid item xs={12} md={3}>
        <TextField
          margin="normal"
          required
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="First Name"
          value={firstName}
          onChange={onFirstNameChanged}
          autoFocus
        />
        </Grid>
        <Grid item xs={12} md={3}>
        <TextField
          margin="normal"
          required
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="Last Name"
          value={lastName}
          onChange={onLastNameChanged}
          autoFocus
        />
        </Grid>
        <Grid item xs={12} md={3}>
        <TextField
          margin="normal"
          required
          id="email"
          label="Email"
          name="email"
          autoComplete="Email"
          value={email}
          onChange={onEmailChanged}
          autoFocus
        />
        </Grid>
        <Grid item xs={12} md={2} className={classes.iconButtonContainer} style={{ paddingTop: 24}}>
          <IconButton aria-label="send invitation" onClick={(_e) => handleSendInvitationClick()}>
            <SendIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20 }} />
    </Paper>
  )
}