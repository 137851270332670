const axios = require('axios');

const endPoint = 'https://api.box.com/2.0';

let credentials = (token) => {
  return { headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Credentials':true }}
}

export const searchBox = async (token, type, query) => {
  try {
    const response = await axios.get(`${endPoint}/search?query=${query}&type=${type}`, credentials(token))
    return response
  } catch(e) {
    //TODO log this error with Rollbar
    return false;
  }
}

export const getEnterpriseMetadataTemplates = async (token, enterpriseId) => {
  try {
    const response = await axios.get(`${endPoint}/metadata_templates/enterprise`, credentials(token))
    return response
  } catch(e) {
    //TODO log this error with Rollbar
    return false;
  }
}