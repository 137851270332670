import { BoxTargetFieldSelector } from './BoxTargetFieldSelector'
import { NerTargetFieldSelector } from './NerTargetFieldSelector'
import { MtcTargetFieldSelector } from './MtcTargetFieldSelector'
import { PreBuiltTargetFieldSelector } from './PreBuiltTargetFieldSelector'

export const TargetFieldSelector = (props) => {
  let content;
  switch(props.templateName) {
    case 'boxFormData':
      content = BoxTargetFieldSelector(props);
      break;
    case 'messageClassification':
      content = MtcTargetFieldSelector(props);
      break;
    case 'multiLabelTextClassification':
      content = MtcTargetFieldSelector(props);
      break;
    case 'namedEntityRecognition':
      content = NerTargetFieldSelector(props);
      break;
    case 'sentimentAnalysis':
      content = PreBuiltTargetFieldSelector(props);
      break;
    case 'commonEntityRecognition':
      content = PreBuiltTargetFieldSelector(props);
      break;
    case 'tabularDataClassification':
      content = MtcTargetFieldSelector(props);
      break;
    case 'industryLabelTagger':
      content = PreBuiltTargetFieldSelector(props);
      break;
    case 'imageTextExtraction':
      content = NerTargetFieldSelector(props);
      break;
    case 'zendesk':
      content = '';
      break;
    case 'zoom':
      content = '';
      break;
    default:
      content = '';
  }
  return content;
}