import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const show = async (credentials, workspaceId, automationId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/automations/${automationId}`, credentials)
  return response
}

export const list = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/automations`, credentials)
  return response
}

export const create = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/automations`, data, credentials)
  return response
}

export const update = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/automations/${data.id}`, data, credentials)
  return response
}

export const test = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/automations/${data.id}/test`, data, credentials)
  return response
}

export const destroy = async (credentials, workspaceId, data) => {
  const response = await axios.delete(`${HYDRA_API}/workspaces/${workspaceId}/automations/${data.id}`, credentials)
  return response
}

