import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { TargetFieldsViewer } from './TargetFieldsViewer'
import { getEnterpriseMetadataTemplates } from '../box/boxApi';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 590,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fieldSelectorBox: {
    width: 600,
    minWidth: 600,
    textAlign: 'start',
    overflowY: 'scroll',
    height: '100vh',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  MuiFormControl: {
    root: {
      minWidth: 600,
      marginBottom: 8,
    }
  },
  submitButton: {
    marginBottom: theme.spacing(3),
  }
}));

const createField = (scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules) => {
  return { scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules }
}

const labelFields = (labelConfig) => {
  return (labelConfig || []).map(fo => createField(fo.scope, fo.sourceName, fo.sourceLabel, fo.label, fo.name, fo.dataType, true, fo.rules))
  
}  


export const NerTargetFieldSelector = (props) => {
  const classes = useStyles();

  const [fieldOptions, setFieldOptions] = useState(labelFields(props.labelConfig));
  const [newField, setnewField] = useState('');

  const fieldEntryTemplate = {
    fieldName: '',
    fieldLabel: '',
    sourceName: 'hydra',
    sourceLabel: 'Hydra',
    scope: 'hydra',
    fieldType: 'text',
    rules: [],
    color: 'green',
    fieldSelected: true,
    writeBack: true
  }

  const onFieldNameChanged = (e) => {
    setnewField(e.target.value);
  }
  const onAddFieldClicked = () => {
    let _field = {...fieldEntryTemplate, fieldName: newField, fieldLabel: newField }
    setFieldOptions([...fieldOptions, _field])
    updateLabelConfig([...fieldOptions, _field])
    setnewField('')
  }
  const updateLabelConfig = (fo = fieldOptions) => {
    //if(labelConfigUpdated == true) {
    let _labelConfig = [];
    fo.forEach(option => {
      if (option.fieldSelected == true) {
        _labelConfig.push({
          name: option.fieldName,
          label: option.fieldLabel,
          sourceName: option.sourceName,
          sourceLabel: option.sourceLabel,
          scope: option.scope,
          dataType: option.fieldType,
          rules: option.rules,
          color: 'green',
          writeBack: true
        })
      }
    })
    props.setLabelConfig(_labelConfig)
  }

  return (
    <Box m={0} className={classes.fieldSelectorBox}>
      <TextField className={classes.formControl} id={`${props.fieldName}-field-name`} label="Data Label" value={newField} onChange={onFieldNameChanged} variant='outlined' />
      <Button
        type="button"
        variant="outlined"
        color="primary"
        size="small"
        className={classes.submitButton}
        onClick={onAddFieldClicked}
      >
        Add Data Label
          </Button>
      <TargetFieldsViewer fieldData={fieldOptions} setFieldData={setFieldOptions} setLabelConfigUpdated={updateLabelConfig} viewerFor='ner' />
    </Box>
  );
}
