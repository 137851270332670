import 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  ruleFormControl: {
    marginBottom: theme.spacing(0),
    minWidth: 380,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ruleSelectorBox: {
    width: 400,
    minWidth: 400,
    marginTop: 15,
    paddingBottom: 10,
    textAlign: 'start',
  },

}));

/**
 * Date data filter component
 * @param {*} props 
 */
export const DateDataRule = (props) => {
  const classes = useStyles();

  const [rule, setRule] = React.useState(props.rules[0] || {matcher: 'none', value: new Date()} );

  const handleMatcherChange = (event) => {
    setRule({ matcher: event.target.value, value: rule.value});
    props.setFieldRules(props.fieldIndex, [{matcher: event.target.value, value: rule.value }]);
    props.setLabelConfigUpdated();
  };

  const handleRuleValue = (date) => {
    setRule({ matcher: rule.matcher, value: date});
    props.setFieldRules(props.fieldIndex, [{ matcher: rule.matcher, value: date }])
    props.setLabelConfigUpdated();
  }

  return (
    <Box m={1}>
      <p>Create a helper rule</p>
      <FormControl className={classes.ruleFormControl}>
      
        <InputLabel id={`${props.fieldName}-rule-matcher`}>Matcher</InputLabel>
        <Select
          id={`${props.fieldName}-rule-matcher-selector`}
          value={rule.matcher}
          onChange={handleMatcherChange}
          className={classes.ruleCondtionSelector}
        >
          <MenuItem value='none'>No Rule</MenuItem>
          <MenuItem value='dateBefore'>Date before</MenuItem>
          <MenuItem value='dateAfter'>Date after</MenuItem>
        </Select>
        <br/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date"
          autoOk={true}
          value={rule.value}
          onChange={ handleRuleValue }
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
     
    </MuiPickersUtilsProvider>
      </FormControl>
    </Box>
  );
}
