import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    }
    
  }
}))

export const ActionDataSelector = (props) => {
  const classes = useStyles();
  const dynamicFieldLabels = props.dynamicFields.map( f => f.label)
  const handleActionDataFieldChange = (_event, values) => {
    props.updateRunCondition({fieldName: values})
  };
  
  return (
    <div>
      <Autocomplete
        id="free-solo-demo"
        classes={{root: classes.root}}
        freeSolo
        options={dynamicFieldLabels}
        value={props.runCondition?.[0]?.fieldName || ''}
        onChange={handleActionDataFieldChange}
        renderInput={(params) => (
          <TextField {...params} label="Data Field" margin="normal" onChange={ (e) => handleActionDataFieldChange(e, e.target.value)}/>
        )}
      />
    </div>
  );
}