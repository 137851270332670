import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ConfirmationDialog = (props) => {

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.handleCancel}
        aria-labelledby={props.dialogTitle}
        aria-describedby={props.dialogText}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="confirmation-dialog-title">
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}