import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { create, update, show, list, destroy } from './connectionsApi'

const connectionsAdapter = createEntityAdapter()

const initialState = connectionsAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const addNewConnection = createAsyncThunk('connections/addNewConnection', async (data) => {
  const response =  await create(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const listConnections = createAsyncThunk('connections/listConnections', async (data) => {
  const response =  await list(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const showConnection = createAsyncThunk('connections/showConnection', async (data) => {
  const response =  await show(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const updateConnection = createAsyncThunk('connections/updateConnection', async (data) => {
  const response =  await update(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const deleteConnection = createAsyncThunk('connections/deleteConnection', async (data) => {
  const response =  await destroy(data.credentials, data.workspaceId, data.payload)
  console.log(response.data?.results)
  return response.data?.results?.id
})

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listConnections.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listConnections.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      connectionsAdapter.upsertMany(state, action.payload)
    },
    [listConnections.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [listConnections.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listConnections.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      connectionsAdapter.upsertMany(state, action.payload)
    },
    [listConnections.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewConnection.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addNewConnection.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      connectionsAdapter.addOne(state, action.payload)
    },
    [addNewConnection.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [updateConnection.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateConnection.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      connectionsAdapter.upsertOne(state, action.payload)
    },
    [updateConnection.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [showConnection.pending]: (state, action) => {
      state.status = 'loading';
    },
    [showConnection.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      console.log(`action payload => ${action.payload}`)
      if(action.payload) {
        connectionsAdapter.upsertOne(state, action.payload)
      }
    },
    [showConnection.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [deleteConnection.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteConnection.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      connectionsAdapter.removeOne(state, action.payload)
    },
    [deleteConnection.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
  }
})


//export const { modelAdded } = modelsSlice.actions;
export default connectionsSlice.reducer;

export const {
  selectAll: selectAllConnections,
  selectById: selectConnectionById,
  selectIds: selectConnectionIds,
} = connectionsAdapter.getSelectors(state => state.connections)

