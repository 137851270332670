import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TargetFieldsViewer } from './TargetFieldsViewer'
import { getEnterpriseMetadataTemplates } from '../box/boxApi';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fieldSelectorBox: {
    width: 620,
    minWidth: 620,
    textAlign: 'start',
    overflowY: 'scroll',
    height: '100vh',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  MuiFormControl: {
    root: {
      minWidth: 620,
      marginBottom: 8,
    }
  },
}));

export const BoxTargetFieldSelector = (props) => {
  const classes = useStyles();
  const [metadataTemplate, setMetadataTemplate] = useState('');
  const [entepriseId, setEnterpriseId] = useState('79532027');
  const [fetchStatus, setFetchStatus] = useState('idle');
  
  const [fieldOptions, setFieldOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [mdTemplateOptions, setMedTemplateOptions] = useState([]);
  
  const updateLabelConfig = (fo = fieldOptions) => {
  //if(labelConfigUpdated == true) {
    let _labelConfig = [];
    fo.forEach(option => {
      if (option.fieldSelected == true) {
        _labelConfig.push ({ name: option.fieldName,
          label: option.fieldLabel,
          sourceName: option.sourceName,
          sourceLabel: option.sourceLabel,
          scope: option.scope,
          dataType: option.fieldType,
          rules: option.rules,
          color: 'green',
          writeBack: true })
      }
    })
    props.setLabelConfig(_labelConfig)
  }
  const handleChange = (event) => {
    setMetadataTemplate(event.target.value);
    let selectedTemplateFields = []
    mdTemplateOptions.forEach( option => { 
      if (option[0].sourceName == event.target.value) {
        selectedTemplateFields = option.map( fo => createField(fo.scope, fo.sourceName, fo.sourceLabel, fo.label, fo.name, fo.dataType, true, []));
        
      }
    })
    if(selectedTemplateFields.length > 0){
      setFieldOptions(selectedTemplateFields);
      updateLabelConfig(selectedTemplateFields);
    }

  };

  const createField = (scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules) => {
    return {scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules }
  }

  const resolveDataType = (typeLabel) => {
    let typeValue = 'unsupported';
    switch (typeLabel) {
      case 'string':
        typeValue = 'text';
        break;
      case 'date':
        typeValue = 'date';
        break;
      case 'float':
        typeValue = 'number';
        break;
    }
    return typeValue; 
  }
  const createFieldOption = (data) => {
    const sourceLabel = data?.displayName;
    const sourceName = data?.templateKey;
    const scope = data?.scope;
    let mappedFields = data?.fields.map( f => ({ name: f.key,
      label: f.displayName,
      color: 'green',
      sourceName: sourceName,
      sourceLabel: sourceLabel,
      scope: scope,
      dataType: resolveDataType(f.type),
      rules: [],
      writeBak: true }));
    return mappedFields;
  }

  const setDisplayLabelConfig = async () => {
    let selectedTemplate;
    if ((props.labelConfig || []).length > 0) {
      selectedTemplate = props.labelConfig[0].sourceName;
    }
    let mdTemplates = await fetchBoxMetadataTemplates();
    setMedTemplateOptions(mdTemplates);
    let _templateOptions = [];
    let _fieldOptions = [];
    mdTemplates.forEach( option => {
      //template has fields
      if(option.length > 0) {
        //create a dropdown entry
        _templateOptions.push(<MenuItem key={option[0].sourceName} value={option[0].sourceName}>{option[0].sourceLabel}</MenuItem>);
        //if the template is in FireStore label config then update the fieldOptions with FireStore rules and selections
        if (selectedTemplate === option[0].sourceName) {
          _fieldOptions = option.map( fo => createField(fo.scope, fo.sourceName, fo.sourceLabel, fo.label, fo.name, fo.dataType, false, []));
          props.labelConfig.forEach( lc => {
            let matchedOption = _fieldOptions.find( o => o.fieldName === lc.name );
            if (matchedOption) {
              matchedOption.rules = lc.rules;
              matchedOption.fieldSelected = true;
            }
          })
        }
      }
    });
    setTemplateOptions(_templateOptions);
    setFieldOptions(_fieldOptions);
    if(selectedTemplate) {
      setMetadataTemplate(selectedTemplate);
    }
  }

  const fetchBoxMetadataTemplates = async () => {
    let _fieldOptions = [];
    let rs = await getEnterpriseMetadataTemplates(props.accessToken, entepriseId);
    (rs.data?.entries || []).forEach(md => {
      _fieldOptions.push(createFieldOption(md))
    });
    return _fieldOptions;
  }

  useEffect(() => {
    if (fetchStatus === 'idle') {
      setDisplayLabelConfig();
    }
  }, [])
  
  return (
    <Box m={0} className={classes.fieldSelectorBox}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="box-metatdata-template-selector">Metadata Template</InputLabel>
        <Select
          labelId="box-metatdata-template-selector-label"
          id="box-metatdata-template-selector-outlined"
          value={metadataTemplate}
          onChange={handleChange}
          label="box-metatdata-template-selector"
        >
          <MenuItem value=''>
            None
          </MenuItem>
          {templateOptions}
        </Select>
      </FormControl>
      <TargetFieldsViewer fieldData={fieldOptions} setFieldData={setFieldOptions} setLabelConfigUpdated={updateLabelConfig}/>
    </Box>
  );
}
