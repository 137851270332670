import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const list = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces`, credentials)
  return response
}

export const show = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}`, credentials)
  return response
}