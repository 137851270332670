import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { DataFilterSelectionList } from './DataFilterSelectionList'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import { Box } from '@material-ui/core';
import { searchBox } from '../box/boxApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  wrapper: {

  }
}));

/**
 * Provides the search capability to find and select Box folders
 * 
 * @param {*} props 
 */
export const BoxFolderSelector = (props) => {
  const classes = useStyles();
  function createData(key, value, label) {
    return { key, value, label };
  }

  const [searchText, setSearchText] = useState('')
  const [foldersList, setFoldersList] = useState(props.triggerConfig)
  const [selected, setSelected] = useState(props.triggerConfig);

  const handleSearchText = (query) => {
    setSearchText(query);
    if(query.length === 0) {
      setFoldersList(props.triggerConfig);
    }
  }

  const fetchBoxFolders = async (e) => {
    e.preventDefault();
    let folders = [];
    let rs = await searchBox(props.boxToken, 'folder', searchText);
    (rs.data?.entries || []).forEach(folder => {
      folders.push(createData('folderId', folder.id, folder.name))
    });
    setFoldersList(folders);
  }

  if (props.hasConnection === false) {
    return (
      <Box>Connect Box to see folder selection options here</Box>
    )
  } else {
    return (
      <div className={classes.wrapper}>
        <Paper component="form" className={classes.root} elevation={0}>
          <TextField
            className={classes.input}
            placeholder='Search Box folders'
            value={searchText}
            onChange={ (e) => handleSearchText(e.target.value) }
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={ (e) => fetchBoxFolders(e) } onSubmit={ (e) => fetchBoxFolders(e) }>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Paper>
        <br />
        <DataFilterSelectionList listData={foldersList} selected={props.triggerConfig} setSelected={props.setTriggerConfig} listIcon={<FolderOpenIcon />} />
      </div>
    );
  }
}
