let Session = (function () {

  const sessionCookieName = 'hydra-session'

  let setSession = (data, days = 7) => {
    data.authHeader = { headers: { Authorization: data.token }}
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = sessionCookieName + '=' + encodeURIComponent(JSON.stringify(data)) + '; expires=' + expires + '; path=/' 
  }

  let getSession = () => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      const data = parts[0] === sessionCookieName ? decodeURIComponent(parts[1]) : r
      const sessionData = JSON.parse(data || '{}')
      if(sessionData?.userId === undefined) {
        return false
      } else {
        return sessionData
      }
    }, '')
  }

  let deleteSession = () => {
    setSession({}, 0);
  }
  
  return {
    getSession: getSession,
    setSession: setSession,
    deleteSession: deleteSession
  }

})()

export default Session