import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppMainLayout } from '../utilities/AppMainLayout'
import { makeStyles } from '@material-ui/core/styles';
import { ModelCard } from './ModelCard'
import Box from '@material-ui/core/Box';
import { templates } from '../models/templateConfigs'
import Grid from '@material-ui/core/Grid'
import {
  listModels,
  selectAllModels,
} from './modelsSlice'
import Session from '../utilities/Session'
import { Notification } from '../utilities/Notification';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modelListGrid: {
    margin: theme.spacing(3),
    height: '100vh',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(10), 
  },
  zeroStateBox: {
    backgroundColor: 'white',
    minHeight: '30vh',
    padding: theme.spacing(5),
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'left',
  },
}));

const getBannerImage = (templateName) => (
  `${process.env.PUBLIC_URL}/assets/images/${(templates.filter(e => templateName === e.name))[0].bannerImage}`
)

export const ModelsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const models = useSelector((state) => selectAllModels(state))
  const modelStatus = useSelector((state) => state.models.status)
  const error = useSelector((state) => state.models.error)

  const [session, setSession] = useState(Session.getSession())
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success' })
  useEffect(() => {
    if (modelStatus === 'idle') {
      dispatch(listModels({ credentials: session.authHeader, workspaceId: session.workspaceId }))
    }
  }, [])

  const modelsZeroState = () => {
    return (
      <Grid container style={{ minHeight: '90vh' }} spacing={0}
        direction="column"
        alignItems="center"
        justify="center">
        <Grid item xs={4} className={classes.zeroStateBox}>
          <Box>
            You can use models to extract data and make predictions. <br /><br />You don't have any models yet. Try creating one...
        </Box>
        </Grid>
      </Grid>
    )
  }

  let content;

  const prepareContent = (gridContent) => {
    return (
      <Grid container direction="row" alignContent="flex-start" className={classes.modelListGrid}>
        {gridContent}
      </Grid>
    )
  }

  if ((models || []).length > 0) {
    const modelCardContent = models.map((model) => (
      <ModelCard key={model.id} id={model.id} media={getBannerImage(model.templateRef)} modelName={model.name} workspaceId={session.workspaceId} status={model.status} score={model.score} trainConfig={model.trainConfig} session={session} setNotificationConfig={setNotificationConfig} />
    ))
    content =  prepareContent(modelCardContent)

  } else if (modelStatus === 'loading') {
    content = ''
    
  } else {
    content = modelsZeroState();
  }
  if (modelStatus === 'failed') {
    const errorContent = <div>{error}</div>
    content = prepareContent(errorContent)
  }

  return (
    <AppMainLayout title='Models' workspaceId={session.workspaceId} fabLink={'/models/new'}>
      {content}
      <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
    </AppMainLayout>
  )
}
