import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'

import Session from './Session'

function authenticated() {
  return (Session.getSession() ? true : false)
}
export const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/users/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}