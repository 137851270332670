import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import InputBase from '@material-ui/core/InputBase'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
  labelInputWhite: {
    margin: theme.spacing(1),
    color: 'white',
    fontSize: '1rem',
    borderBottom: "1px solid white",
  },
  labelInputBlack: {
    margin: theme.spacing(1),
    color: 'black',
    fontSize: '1rem',
    borderBottom: "1px solid black",
  },
  editButton: {
    textTransform: 'capitalize',
    fontSize: '1rem',
  }
})
)

export const EditableLabel = (props) => {
  const classes = useStyles()
  const [editable, setEditable] = useState(false);
  
  if (editable === false) {
    return (
      <Box className={classes.root}>
        <Button
          variant="text"
          color={'inherit'}
          size="small"
          className={classes.editButton}
          endIcon={<EditOutlinedIcon />}
          disableRipple={true}
          onClick={() => setEditable(true)}
        >
          {props.label}
        </Button>
      </Box>
    )
  } else {
    return (
      <Box className={classes.root}>
        <InputBase
          autoFocus={true}
          className={(props.mode === 'dark') ? classes.labelInputBlack : classes.labelInputWhite }
          placeholder="Give it a name"
          inputProps={{ 'aria-label': 'naked' }}
          onBlur={() => setEditable(false)}
          onChange={(e) => props.setLabel(e.target.value)}
          value={props.label}
        />
      </Box>
    )
  }
}
