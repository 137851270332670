import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory, useLocation } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { Copyright} from '../utilities/Copyright'
import { processPasswordReset } from './usersSlice'
import { BlockingErrorNotice } from '../utilities/BlockingErrorNotice';
import { errors } from '../../configs/notificationTexts'
import { HydraLogo } from '../utilities/HydraLogo';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  blockingErrorNotice: {
    width: '100%',
    backgroundColor: '#efc0c0',
    margin: '10px'
  }
}));



export const PasswordReset = () => {

  const useQuery = () =>  (new URLSearchParams(useLocation().search));
  const classes = useStyles();
  const resetToken = useQuery().get('token');
  const history = useHistory()
  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const [resetRequestStatus, setResetRequestStatus] = useState('idle') 
  const [resetFailed, setResetFailed] = useState(false) 

  const onPasswordChanged = (e) => setPassword(e.target.value)
  
  const canReset =
    [password].every(Boolean) && resetRequestStatus === 'idle'

  const onResetClicked = async () => {
    if(canReset) {
      try {
        setResetRequestStatus('pending')
        const resultAction = await dispatch(processPasswordReset({ resetToken, password }))
        unwrapResult(resultAction)
        setPassword('')
        history.push('/')
      } catch (err) {
        setResetFailed(true)
        setResetRequestStatus('idle')
      }
    }
  }
  
  return (
    <Container component="main" maxWidth="xs" style={{ paddingTop: '50px'}}>
      <CssBaseline />
      <HydraLogo/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">Reset Password</Typography>
        { resetFailed &&
          <BlockingErrorNotice message={errors.PASSWORD_RESET_FAILED}/>
        }
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={onPasswordChanged}
            autoComplete="current-password"
            onKeyPress={ (ev) => 
              {
                if (ev.key === 'Enter') {
                  onResetClicked()
                  ev.preventDefault()
                }
              }
            }
          />
          
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onResetClicked} disabled={!canReset}
          >
            Reset Password
          </Button>
          <Grid container>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )

}