import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { create, update, test, list, destroy } from './automationsApi'

const automationsAdapter = createEntityAdapter()

const initialState = automationsAdapter.getInitialState({
  status: 'idle',
  error: null
})
export const listAutomations = createAsyncThunk(
  'automations/list',
  async (data) => {
    const response = await list(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

export const addNewAutomation = createAsyncThunk('automations/addNewAutomation', async (data) => {
  const response =  await create(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const updateAutomation = createAsyncThunk('automations/updateAutomation', async (data) => {
  const response =  await update(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const testAutomation = createAsyncThunk('automations/testAutomation', async (data) => {
  console.log(data)
  const response =  await test(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const deleteAutomation = createAsyncThunk('automations/deleteAutomation', async (data) => {
  const response =  await destroy(data.credentials, data.workspaceId, data.payload)
  console.log(response.data?.results)
  return response.data?.results?.id
})

const automationsSlice = createSlice({
  name: 'automations',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listAutomations.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listAutomations.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      automationsAdapter.upsertMany(state, action.payload)
    },
    [listAutomations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAutomation.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addNewAutomation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      automationsAdapter.addOne(state, action.payload)
    },
    [addNewAutomation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [updateAutomation.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateAutomation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      automationsAdapter.upsertOne(state, action.payload)
    },
    [updateAutomation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [testAutomation.pending]: (state, action) => {
      state.status = 'loading';
    },
    [testAutomation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [testAutomation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [deleteAutomation.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteAutomation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      automationsAdapter.removeOne(state, action.payload)
    },
    [deleteAutomation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
  }
})

//export const { modelAdded } = modelsSlice.actions;
export default automationsSlice.reducer;

export const {
  selectAll: selectAllAutomations,
  selectById: selectAutomationById,
  selectIds: selectAutomationIds,
} = automationsAdapter.getSelectors(state => state.automations)
