import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { CreateConnectionButton } from './CreateConnectionButton'
import { BoxFolderSelector } from './BoxFolderSelector'
import { HYDRA_API } from '../../configs/env'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 600,
  },
  basicInfoContainer: {
    textAlign: 'left',
  },
  inputElement: {
    marginBottom: theme.spacing(4),
    minWidth: 400,
  },
}))


export const ModelMinConfidence = (props) => {
  const classes = useStyles();

  const [error, setError] = useState([]);

  const onMinConfidenceChanged = (event) => {
    const minConfidence = Number.parseInt(event.target.value);
    if(isNaN(minConfidence)) {
      setError(['Provide a number below 100'])
      props.setMinConfidence('');
    
    } else {
      setError([]);
      props.setMinConfidence(minConfidence);
    }
  }

  return (<Box className={classes.basicInfoContainer}>
    <CssBaseline />
    <TextField className={classes.formControl} id={`${props.fieldName}-field-name`} label="Minimum Confidence Threshold" value={props.minConfidence} onChange={onMinConfidenceChanged} variant='outlined' error={error.length > 0 ? true : false} helperText={error?.[0]}/>

  </Box>)
}