import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { BlockingErrorNotice } from './BlockingErrorNotice';
import { errors } from '../../configs/notificationTexts'
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

import { selectUserById, updateUserInfo } from '../users/usersSlice'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    padding: theme.spacing(3),
  },
  form: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
    '& .MuiFormControl-root': {
      minWidth: '90%',
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    visibility: 'hidden',
  }
}))

/**
 * Account settings component
 * 
 */
export const AccountSettings = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [session, setSession] = useState(Session.getSession())
  const user = useSelector( state => selectUserById(state, session.userId));

  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  
  const [companyName, setCompanyName] = useState('')
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const [updateFailed, setUpdateFailed] = useState(false)

  const onFirstNameChanged = (e) => setFirstName(e.target.value)
  const onLastNameChanged = (e) => setLastName(e.target.value)
  const onCompanyNameChanged = (e) => setCompanyName(e.target.value)

  const canUpdate =
    [firstName, lastName].every(Boolean) && updateRequestStatus === 'idle'

  const onUpdateClicked = async () => {
    if(canUpdate) {
      try {
        const userUpdate = await dispatch(updateUserInfo({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          userId: session.userId,
          payload: {
            firstName: firstName,
            lastName: lastName,
            id: session.userId,
          },
        }))
        unwrapResult(userUpdate);
        setUpdateFailed(false);
        props.setNotificationConfig({show: true, notice: 'Account updated', severity: 'success'});
      } catch (err) {
        setUpdateFailed(true);
        props.setNotificationConfig({show: true, notice: 'Something went wrong', severity: 'error'});
      }
    }
  }

  useEffect(() => {
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
  }, [user])

  return (
    <Box component='span'>
      <Typography className={classes.sectionTitle}>
        Update Information
                </Typography>
        { updateFailed &&
          <BlockingErrorNotice message={errors.ACCOUNT_UPDATE_FAILED}/>
        }
      <form className={classes.form} noValidate>
        <TextField
          margin="normal"
          required
          id="firstName"
          value={firstName}
          label="First Name"
          name="firstName"
          onChange={onFirstNameChanged}
          autoComplete="First Name"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          name="lastName"
          value={lastName}
          label="Last Name"
          id="lastName"
          onChange={onLastNameChanged}
          autoComplete="Last Name"
        />
        <TextField
          margin="normal"
          required
          name="companyName"
          value={companyName}
          label="Company Name"
          id="companyName"
          onChange={onCompanyNameChanged}
          autoComplete="Last Name"
          className={classes.hidden}
        />

        <Button
          type="button"
          color="primary"
          className={classes.submitButton}
          onClick={onUpdateClicked}
          disabled={!canUpdate}
        >
          Update
          </Button>
      </form>
    </Box>
  )
}