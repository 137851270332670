import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { AutomationSelectorParamMap } from './AutomationSelectorParamMap';
import { list } from './automationsApi';
import Session from '../utilities/Session'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fieldSelectorBox: {
    width: 600,
    minWidth: 600,
    textAlign: 'start',
  },
  MuiFormControl: {
    root: {
      minWidth: 600,
      marginBottom: 8,
    }
  } 
}));

export const AutomationSelector = (props) => {
  const classes = useStyles();
  const [fetchStatus, setFetchStatus] = useState('idle');
  const [session, setSession] = useState(Session.getSession())

  const [automationOptions, setAutomationOptions] = useState([]);
  const [startFieldOptions, setStartFieldOptions] = useState({});
  
  const handleChange = (event) => {
    props.setSelectedAutomation(event.target.value);
    props.setAutomationStartFields(startFieldOptions[event.target.value] || []);
  };

  const displayAutomationOptions = async () => {
    let _automationOptions = [];
    let _startFieldSetOptions = {};
    
    const rs = await fetchAutomations();
    (rs.data?.results || []).forEach(automation => {
      _automationOptions.push(<MenuItem key={automation.id} value={automation.id}>{automation.name}</MenuItem>);
      _startFieldSetOptions[automation.id] = createStartFieldOption(automation);
    });
    
    setAutomationOptions(_automationOptions);
    setStartFieldOptions(_startFieldSetOptions);
  }

  const fetchAutomations = async () => {
    const rs = await list(session.authHeader, session.workspaceId);
    return rs;
  }

  const createStartFieldOption = (automation) => {
    const startNode = automation.nodes.find( node => node.type == 'start');
    return (startNode.input || []).map( i => ({ name: i.key, label: i.key, dataType: i.dataType, value: '' }));
  }

  useEffect(() => {
    if (fetchStatus === 'idle') {
      displayAutomationOptions();
    }
  }, [])

  return (
    <Box m={0} className={classes.fieldSelectorBox}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="box-metatdata-template-selector">Select Automation</InputLabel>
        <Select
          labelId="box-metatdata-template-selector-label"
          id="box-metatdata-template-selector-outlined"
          value={props.selectedAutomation}
          onChange={handleChange}
          label="box-metatdata-template-selector"
        >
          <MenuItem value=''>
            None
          </MenuItem>
          {automationOptions}
        </Select>
      </FormControl>
      <AutomationSelectorParamMap varFields={props.automationStartFields} setVarFields={props.setAutomationStartFields} paramOptions={props.paramOptions}/>
    </Box>
  );
}
