import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add'
import CssBaseline from '@material-ui/core/CssBaseline'
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { EditableLabel } from '../utilities/EditableLabel'
import { ActionElement } from '../actions/ActionElement'
import { ActionEditor } from '../actions/ActionEditor'
import Session from '../utilities/Session'
import { useHistory } from 'react-router-dom'
import { list } from '../actions/actionsApi'
import { show } from './automationsApi'
import { ConfirmationDialog } from '../utilities/ConfirmationDialog'

import { compileDynamicOptions } from '../utilities/dynamicFieldUtils'
import { Notification } from '../utilities/Notification';

import { addNewAutomation, updateAutomation, testAutomation, deleteAutomation } from './automationsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'hidden',
  },
  addActionFab: {
    boxShadow: 'unset',
  },
  actionsPane: {
    backgroundColor: '#FCFCFC',
    height: '100vh',
    overflowY: 'scroll',
  },
  configPane: {
    backgroundColor: 'white',
    overflowY: 'scroll',
    height: '100vh',
  },
  actionsBox: {
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  appBar: {
    backgroundColor: '#4B6BD6',
    boxShadow: 'unset',
    zIndex: 1,
  },
  saveButton: {
    color: 'white',
    fontWeight: 600,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  linearProgressBar: {
    zIndex: 9,
    minWidth: '100%',
    position: 'absolute',
  },
}));

export const AutomationBuilder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  
  const [id, setId] = useState(useParams().automationId)

  const automationStatus = useSelector((state) => state.automations.status)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success'})

  const [session, setSession] = useState(Session.getSession())
  const [automationName, setAutomationName] = useState('Give it a name');
  const [nodes, setNodes] = useState([{actionName: 'start', type: 'start', actionDescription: 'Start', condition: [], input: [], output: [], stepNumber: 0, connectionRef: ''}]);
  const [selectedNode, setSelectedNode] = useState(0);

  const [actionTemplates, setActionTemplates] = useState([]);

  const navigateActionsList = () => {
    history.push(`/workspaces/${session.workspaceId}/automations/`);
  }

  const promptDelete = async () => {
    if (automationStatus != 'loading') {
      setShowDeleteConfirm(true);
    }
  }

  const runAutomationDelete = async () => {
    if (automationStatus != 'loading') {
      try {
        if (id) {
          const automationDelete = await dispatch(deleteAutomation({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: {
              id: id,
            }
          }))
          unwrapResult(automationDelete)
        }
        setShowDeleteConfirm(false)
        history.push(`/workspaces/${session.workspaceId}/automations`);
      } catch (e) {

      }
    }
  }

  const preparePayload = (id) => {
    let payload
    let dynamicFields = compileDynamicOptions(nodes, nodes.length, true);
    let _nodes = [...nodes]
    _nodes[0].input = dynamicFields
    setNodes(_nodes)
    if(id) {
      payload = { id: id, name: automationName, nodes: _nodes }
    } else {
      payload = { name: automationName, nodes: _nodes }
    }
    return payload
  }

  const saveAutomation = async () => {
    if (automationStatus != 'loading') {
      try {
        const automationPayload = preparePayload(id)
        console.log(automationPayload)
        if(id) {
          const automationUpdate = await dispatch(updateAutomation({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: automationPayload,
          }))
          unwrapResult(automationUpdate)
        } else {
            const automationSave = await dispatch(addNewAutomation({
            credentials: session.authHeader,
            workspaceId: session.workspaceId,
            payload: automationPayload,
          }))
          const automation = unwrapResult(automationSave)
          setId(automation.id);
          history.push(`/workspaces/${session.workspaceId}/automations/${automation.id}`);
        }
      } catch (err) {

      }
    }

  }

  const runAutomationTest = async (launchVars) => {
    if (automationStatus != 'loading') {
      try {
        const automationTest = await dispatch(testAutomation({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: {...launchVars, id },
        }))
        unwrapResult(automationTest)
        setNotificationConfig({show: true, notice: 'Automation test queued', severity: 'success'})
      } catch(err) {
        setNotificationConfig({show: true, notice: 'Something went wrong. Try again.', severity: 'error'})
      }
    }
  }

  const generateNodes = () => {
    let nodesPreview = nodes.map((node, index) => (
      <ActionElement key={index}  actionId={index} actionName={node.actionName} actionDescription={node.actionDescription} actionLogo={node.actionLogo} selectedNode={selectedNode} setSelectedNode={setSelectedNode}/>
    ))
    return nodesPreview;
  }

  /*
  const showNodeEditor = (nodes) => {
    let _nodes = cloneDeep(nodes);
    if (_nodes[selectedNode].type === 'start') {
      let fieldsList = compileDynamicOptions(_nodes, _nodes.length, true)
      let varOptions = fieldsList.map(f => ({ 'name': f, 'value': ''}))
      return <ActionStartConfig varFields={varOptions}/>
    } else {
      return <ActionEditor nodes={_nodes} selectedNode={selectedNode} setNodes={setNodes} actionTemplates={actionTemplates}/>
    }
  }
  */

  const addNewNode = () => {
    let node = { actionId: 0, actionName: '', type: 'action', actionDescription: 'Click to edit', condition: [], input: [], output: [], stepNumber: 0, connectionRef: ''};
    setNodes([...nodes, node]);
  }

  useEffect(() => {
    list(session.authHeader, session.workspaceId).then( rs => {
      setActionTemplates(rs.data?.results);
    });
  }, [session])

  useEffect(() => {
    if (id) {
      show(session.authHeader, session.workspaceId, id).then( rs => { 
        ReactDOM.unstable_batchedUpdates(() => {
          setAutomationName(rs.data.name);
          setNodes(rs.data.nodes);
          console.log('done loading nodes ...');
        })
      })
    }
  }, [id])

  return (
    <div className={classes.root}>
      { automationStatus === 'loading' ? <LinearProgress className={classes.linearProgressBar} /> : ''}
      <CssBaseline />
      <AppBar position="fixed"
        className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton onClick={navigateActionsList} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <ArrowBackIcon />
          </IconButton>   
          <EditableLabel label={automationName} setLabel={setAutomationName} />
          <Button className={classes.saveButton} onClick={promptDelete}> Delete </Button>
          <Button className={classes.saveButton} onClick={saveAutomation}> Save </Button>
        </Toolbar>
        <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
      </AppBar>
      <div className={classes.toolbar} />
      <Grid container spacing={3} style={{width: '100%'}}>
        <Grid item xs={12} md={4} className={classes.actionsPane}>
          <Box className={classes.actionsBox}>
            {generateNodes()}
            <Fab color='primary' aria-label='add' className={classes.addActionFab} size='small' onClick={addNewNode}>
              <AddIcon />
            </Fab>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className={classes.configPane}>
          <ActionEditor nodes={nodes} setSelectedNode={setSelectedNode}  selectedNode={selectedNode} setNodes={setNodes} actionTemplates={actionTemplates} testAutomation={runAutomationTest}/>
        </Grid>
      </Grid>
      <ConfirmationDialog show={showDeleteConfirm} dialogTitle={'Are you sure'} dialogText={'This can not be undone'} handleConfirm={runAutomationDelete} handleCancel={() => setShowDeleteConfirm(false)} />
    </div>
  );
}
