import React from 'react';
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hydraLogo: {
    height: '50px',
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    cursor: 'pointer',
  }
}))

export const HydraLogo = () => {
  const history = useHistory();
  const classes = useStyles();
  return(
    <Box onClick={ _e => history.push("/") }>
      <img src={`${process.env.PUBLIC_URL}/assets/images/hydra-logo.png`} className={classes.hydraLogo}/>
    </Box>
  )
}