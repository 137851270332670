import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { AppMainLayout } from './AppMainLayout'
import { AccountSettings } from './AccountSettings'
import { UpdatePasswordSettings } from './UpdatePasswordSettings'
import { EditableLabel } from './EditableLabel'
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { InviteTeamMember} from './InviteTeamMember'
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

import { selectAllUsers } from '../users/usersSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  settingsBox: {
    minHeight: '70vh',
    padding: theme.spacing(3)
  },
  settingsSectionBox: {
    backgroundColor: 'white',
    minHeight: '10vh',
    width: '100%',
    marginBottom: 30,
  },
  memberPanel: {
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingTop: 20,
  },
  memberContainer: {
    textAlign: 'start',
  },
  hidden: {
    visibility: 'hidden',
  },
  purple: {
    backgroundColor: deepOrange[200],
  }
}));

export const TeamSettings = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [session, setSession] = useState(Session.getSession())
  const users = useSelector( state => selectAllUsers(state));
  const userStatus = useSelector((state) => state.users.status)
  
  const [teamMembers, setTeamMembers] = useState(props.teamMembers || []);
  const [showAddUserForm, setShowAddUserForm] = useState(false)

  const handleDeleteTeamMemberClick = (memberId) => {
      alert(memberId)
      //handle team member deletion here
  }

  const handleInviteTeamMemberClick = () => {
    setShowAddUserForm(true)
    //let teamMembersCopy = [...teamMembers]
    //teamMembersCopy.push({firstName: 'Jason', lastName: 'Smith', email: 'jsmith@google.com'})
    //setTeamMembers(teamMembersCopy)
  }

  return (

    <Box component='div' className={classes.memberContainer}>
      { users.map((item, index) =>
      <Paper className={classes.settingsSectionBox} elevation={0} key={item.id}>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          spacing={3}>
          <Grid item xs={12} md={1}>
            <Avatar className={classes.purple}>{ `${item.firstName.charAt(0)}${item.lastName.charAt(0)} `} </Avatar>
          </Grid>
          <Grid item xs={12} md={9} className={classes.memberPanel}>
            <Typography style={{ paddingTop: '10px' }}>
              { `${item.firstName} ${item.lastName} (${item.email})`} 
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
          <IconButton aria-label="delete" onClick={ (_e) => handleDeleteTeamMemberClick(item.id)} className={classes.hidden}>
            <DeleteIcon />
          </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20 }}/>
      </Paper>
      )
    }
    
    { showAddUserForm ? 
      <InviteTeamMember notificationConfig={props.notificationConfig} setNotificationConfig={props.setNotificationConfig}/> :
      <Button onClick={handleInviteTeamMemberClick}>Invite to the team</Button>
    }
    </Box>
  )
}