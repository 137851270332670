import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Session from '../utilities/Session'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import InputIcon from '@material-ui/icons/Input';
import { templates } from './templateConfigs'

import { isEmpty } from 'lodash'

import { addNewConnection, deleteConnection, listConnections } from './connectionsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'


const useStyles = makeStyles((theme) => ({
  connectionAddButton: {
    marginLeft: theme.spacing(2),
  },
  apiKeyField: {
    width: theme.spacing(30),
  },
  longInputField: {
    width: theme.spacing(60),
    marginBottom: theme.spacing(2),
  }
}))

export const ConnectionConfig = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [session, setSession] = useState(Session.getSession())
  const connectionStatus = useSelector((state) => state.connections.status)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [apiKey, setApiKey] = useState('');
  const [connection, setConnection] = useState('')

  const onApiKeyChanged = (e) => setApiKey(e.target.value)
  
  const saveConnection = async () => {
    if (connectionStatus != 'loading') {
      try {
        const connectionSave = await dispatch(addNewConnection({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { auth: { apiKey: apiKey }, name: props.provider, provider: props.provider },
        }))
        const connection = unwrapResult(connectionSave)
        setConnection(connection)
      } catch (err) {

      }
    }
  }

  const runDeleteConnection = async () => {
    if (connectionStatus != 'loading') {
      try {
        const connectionDelete = await dispatch(deleteConnection({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { id: connection.id },
        }))
        const rs = unwrapResult(connectionDelete)
        setConnection({})
      } catch (err) {

      }
    }
  }

  useEffect( () => {
    if (connectionStatus != 'loading' && props.provider) {  
      try {
        dispatch(listConnections({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { provider: props.provider }
        })).then( connection => {
          const fetchedConnection = unwrapResult(connection)
          if(!fetchedConnection.length) {
            setConnection({})
          } else {
            setConnection(fetchedConnection[0])
          }
        })
      } catch (err) {
      }
    }
    
  }, [props.provider])

  const apiBox = () => {
    return (<Box>
      <TextField disabled={!isEmpty(connection)} className={classes.apiKeyField} placeholder={'API key'} type="password" onChange={onApiKeyChanged}></TextField>
      { isEmpty(connection) &&
        <Button className={classes.connectionAddButton} size='small' variant='outlined' onClick={saveConnection}>Connect {props.provider}</Button>
      }
      { !isEmpty(connection) &&
        <Button className={classes.connectionAddButton} size='small' variant='outlined' onClick={runDeleteConnection}>Disconnect {props.provider}</Button>
      }
    </Box>)
  }

  const tokenBox = () => {
    return (<Box>
      <TextField disabled={!isEmpty(connection)} className={classes.longInputField} placeholder={'URL'} type="input" value={(props.provider == 'webhook') ? '' : props.provider} onChange={ (e) => (props.updateProvider(e.target.value))}></TextField>
      <br/>
      <TextField disabled={!isEmpty(connection)} className={classes.longInputField} placeholder={'Authorization header'} type="password" value={apiKey} onChange={onApiKeyChanged}></TextField>
      { isEmpty(connection) &&
        <Button className={classes.connectionAddButton} size='small' variant='outlined' onClick={saveConnection}>Connect Webhook</Button>
      }
      { !isEmpty(connection) &&
        <Button className={classes.connectionAddButton} size='small' variant='outlined' onClick={runDeleteConnection}>Disconnect Webhook</Button>
      }
    </Box>)
  }

  const returnPath = () =>  Buffer.from(window.location.href).toString('base64')
  
  const oAuthBox = () => {
    return (
      <div>
        <Button
          href={`${templates[props.provider]?.authLink}workspace=${session.workspaceId}&return_path=${returnPath()}`}
          disabled={!isEmpty(connection)}
          variant="contained"
          color="primary"
          disableElevation={true}
          size="medium"
          className={classes.button}
          endIcon={!isEmpty(connection) ? <CloudDoneIcon /> : <InputIcon />}
  
        >
          {!isEmpty(connection) ? `${props.provider} Is Connected` : `Connect ${props.provider}`}
  
        </Button>
        { !isEmpty(connection) &&
          <Button size='small' aria-label="delete" variant='outlined' onClick={runDeleteConnection} className={classes.connectionAddButton}>
            Disconnect {props.provider}
          </Button>
        }
      </div>
    );
  }

    switch(templates[props.provider]?.connectionType) {
      case 'apiKey':
        return apiBox();
        break;
      case 'oAuth':
        return oAuthBox();
        break;
      case 'token':
        return tokenBox();
        break;
      default: //handles web service providers
        return tokenBox();
    }
}