import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ModelTemplateCard } from './ModelTemplateCard'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  modelListGrid: {
    margin: theme.spacing(1),
    padding: 0,
    width: '100%',
  },
}));

export const ModelTemplates = (props) => {
  const classes = useStyles();
  let content = [];
  if(props.modelId === undefined) {
    content = props.templates.map((template) => (
      <ModelTemplateCard key={template.id} id={template.id} media={`${process.env.PUBLIC_URL}/assets/images/${template.bannerImage}`} description={template.description} handleSelection={props.handleSelection} selected={template.id === props.selected} disabled={!template.generallyAvailable} ga={template.generallyAvailable}/>
    ))
  } else if (props.selected > -1){
    content = props.templates.filter((template) => (
      template.id === props.selected
    )).map((selectedTemplate) => 
      <ModelTemplateCard key={selectedTemplate.id} id={selectedTemplate.id} media={`${process.env.PUBLIC_URL}/assets/images/${selectedTemplate.bannerImage}`} description={selectedTemplate.description} handleSelection={props.handleSelection} selected={true} disabled={true} ga={selectedTemplate.generallyAvailable}/>
    );
  }

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      className={classes.modelListGrid}
    >
      {content}
    </Grid>

  )
}
