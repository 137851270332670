import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}

export const Notification = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setNotificationConfig({ show: false, notice: props.notificationConfig.notice, severity: props.notificationConfig.severity });
  };
  return (
    <div className={classes.root}>
      <Snackbar open={props.notificationConfig.show} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.notificationConfig.severity}>
          {props.notificationConfig.notice}
        </Alert>
      </Snackbar>
    </div>
  )
}