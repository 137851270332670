
export const validations = Object.freeze({
  'EMAIL': 1,
  'PASSWORD': 2
})
export const validateField = (value, validation) => {
  let result = true
  switch(validation) {
    case validations.EMAIL:
      result = /.+@.+\.[A-Za-z]+$/.test(value)
      break;
    case validations.PASSWORD:
      result = /^[a-zA-Z0-9*.!@#$%^&(){}_><]{8,30}$/.test(value)
      break;
    default:
      result = true
  }
 return result
}