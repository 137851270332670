import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const list = async (credentials, workspaceId, payload) => {
  let response = {}
  if(payload.provider) {
    response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/connections?provider=${payload.provider}`, credentials)
  } else {
    response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/connections`, credentials)
  }
  return response
}

export const show = async (credentials, workspaceId, connectionId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/connections/${connectionId}`, credentials)
  return response
}

export const create = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/connections`, data, credentials)
  return response
}

export const update = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/connections/${data.id}`, data, credentials)
  return response
}

export const getCredentials = async (credentials, workspaceId, provider) => {
  let response;
  try {
    response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/connections/${provider}/credentials`, credentials)
  } catch(e) {
    console.log('error => ', e);
  }
  return response
}

export const destroy = async (credentials, workspaceId, data) => {
  const response = await axios.delete(`${HYDRA_API}/workspaces/${workspaceId}/connections/${data.id}`, credentials)
  return response
}
