import React, { useState, createElement } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import { ModelIcon } from './ModelIcon';
import FlashOnIcon from '@material-ui/icons/FlashOn'
import AddIcon from '@material-ui/icons/Add'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { NavLink } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  appBar: {
    backgroundColor: '#051f77',
    boxShadow: 'unset',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuList: {
    paddingTop: '20vh'
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  selectedNav: {
    '& svg': {
      color: '#4b6BD6',
    },
    '& div:not(:hover)': {
      backgroundColor: '#F2F2F2',
    }
  },
  nav: {
    textDecoration: 'none',
    '& .MuiTouchRipple-root': {
      color: '#4b6BD6',
    }
  },
  communityInvite: {
    textAlign: 'right',
    right: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: 5,
    position: 'fixed',
    float: 'right',
    fontSize: '1rem',
    visibility: 'hidden',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(6),
  },
  logoutButton: {
    bottom: theme.spacing(2),
    position: 'absolute',
    textDecoration: 'none',
    width: theme.spacing(7) + 1,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.2em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }

}));

/**
 * Provides the main application layout 
 * includes the side navigation, app bar and a FAB
 * renders passed in children in the main content area
 * rendrs the Fab with props fabTarget
 * @param {*} props 
 */
export const AppMainLayout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const MenuIcons = {
    wifiTetheringIcon: WifiTetheringIcon,
    dashboardIcon: DashboardIcon,
    settingsIcon: SettingsIcon,
    flashOnIcon: FlashOnIcon,
    modelIcon: ModelIcon,
  }

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
          <div className={classes.communityInvite}>🎉 <Button href='https://discord.gg/RQKFF4UNGh' target='_blank' style={{ color: '#FCFCFC', textTransform: 'capitalize' }}>Join us on the community chat</Button></div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.menuList}>
          {[{ name: 'Dashboard', icon: 'dashboardIcon', link: `/workspaces/${props.workspaceId}/dashboard` },
          { name: 'Models', icon: 'modelIcon', link: `/workspaces/${props.workspaceId}/models` },
          { name: 'Automations', icon: 'flashOnIcon', link: `/workspaces/${props.workspaceId}/automations` },
          { name: 'Settings', icon: 'settingsIcon', link: `/workspaces/${props.workspaceId}/settings` }].map((item, index) => (
            <NavLink key={item.name} to={item.link} className={classes.nav} activeClassName={classes.selectedNav}>
              <ListItem button key={item.name}>
                <ListItemIcon>{createElement(MenuIcons[item.icon])}</ListItemIcon>
                <ListItemText primary={item.name} color='primary' />
              </ListItem>
            </NavLink>
          ))}
        </List>
        <NavLink key='logout' to='/users/logout' className={`${classes.nav} ${classes.logoutButton}`}>
              <ListItem button key={'logout'}>
                <ListItemIcon> <PowerSettingsNewIcon /></ListItemIcon>
                <ListItemText primary={'logout'} />
              </ListItem>
            </NavLink>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        {!props.hideFab &&
          <Fab onClick={(e) => history.push(`/workspaces/${props.workspaceId}${props.fabLink}`)} color='primary' aria-label='add' className={classes.fab}>
            <AddIcon />
          </Fab>
        }
      </main>
    </div>
  );
}
