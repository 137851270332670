import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { list } from './reportsApi'

const reportsAdapter = createEntityAdapter()

const initialState = reportsAdapter.getInitialState({
  status: 'idle',
  error: null
})
export const listReports = createAsyncThunk(
  'reports/list',
  async (data) => {
    const response = await list(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listReports.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listReports.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      reportsAdapter.upsertMany(state, action.payload)
    },
    [listReports.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  }
})

export default reportsSlice.reducer;

export const {
  selectAll: selectAllReports,
  selectById: selectReportById,
  selectIds: selectReportIds,
} = reportsAdapter.getSelectors(state => state.reports)