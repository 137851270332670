import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router";
import Box from '@material-ui/core/Box'
import { unwrapResult } from '@reduxjs/toolkit'
import { AppSubLayout } from '../utilities/AppSubLayout'
import { makeStyles } from '@material-ui/core/styles';
import { selectModelById, showModel, predictModel } from './modelsSlice'
import Session from '../utilities/Session'
import { Notification } from '../utilities/Notification';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  zeroStateBox: {
    backgroundColor: 'white',
    minHeight: '30vh',
    padding: theme.spacing(5),
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'left',
  },
  dataInput: {
    margin: '3vh',
    minWidth: '90%',
  },
  predictButton: {
    float: 'left',
    marginBottom: '3vh',
    marginLeft: '5%',
  },
  resultsBox: {
    display: 'inline-table',
    backgroundColor: '#F9F9F9',
    width: '90%',
    marginTop: '1%',
    textAlign: 'left',
    padding: '10px',
    borderRadius: '5px',
    minHeight: '20vh',
    fontSize: '1.3em',
  }
}));


const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n)

const preprocField = (n) => isNumeric(n) ?  parseFloat(n) : (n || '').trim()

//duplicated in https://github.com/hpieris/hydra-webhook-service-cf/blob/dfc33ec6ce265f0d4cd66bbb43a1b019b474a169/src/processor.js#L4
//TODO refactor
const dataProcessor = (dataString) => {
  let data = {}
  let items = dataString.split(',')
  items.forEach(e => {
    const entry = e.split('=')
    if (!!(entry?.[0].trim())) data[entry?.[0].trim()] = preprocField(entry?.[1] || '')
  })
  return data
}


export const ModelPredictionPreview = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [id, setId] = useState(useParams().modelId)
  const modelStatus = useSelector((state) => state.models.status)
  
  const [session, setSession] = useState(Session.getSession())
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success' })

  const [inputData, setInputData] = useState('')
  const [results, setResults] = useState([])
 
  const model = useSelector(state => selectModelById(state, id))
  
  const canPredict = model?.status === 1

  const formatData = (data) => {
    let fData = data
    switch(model.clfType) {
      case 'mdc':
        fData = dataProcessor(data)
        break;
      default:
        fData = data
    }
    return fData
  }

  const formatResults = (res) => {
    let fRes = []
    try {
      res.forEach( r => {
        fRes.push(<p><b>{r.name}</b>: {Array.isArray(r.value) ? r.value.join(', ') : r.value }</p>)
      })
    } catch(e) {
      console.log(e)
    }
    return fRes
  }

  const onPredictClicked = async () => {
    if (modelStatus != 'loading') {
      
      try {
        const predictions = await dispatch(predictModel({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { id: id, data: formatData(inputData) },
        }))
        
        setResults(unwrapResult(predictions))
      } catch (e) {
        console.log(e);
        setNotificationConfig({ show: true, notice: e, severity: 'error' })
      }
    }
  }

  return (
    <AppSubLayout title={model?.name} workspaceId={session.workspaceId}>
      <form className={classes.form} noValidate>
      <TextField id="outlined-basic" value={inputData} onChange={ e => setInputData(e.target.value)} className={classes.dataInput} label="Input Data" variant="outlined" rows={5} multiline/>
      <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onPredictClicked}
              className={classes.predictButton}
              disableElevation
              disabled={!canPredict}
            >
              Predict
            </Button>
      </form>
      <Box className={classes.resultsBox}>
        {formatResults(results)}
        {!canPredict &&
          <p>Model is inactive.</p>
        }
      </Box>
      <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
    </AppSubLayout>
  )
}
