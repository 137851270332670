export const getDynamicFields = (input) => {
  let found = [],
    rxp = /{([^}]+)}/g,
    curMatch;
  while (curMatch = rxp.exec(input.value)) {
    found.push({key: curMatch[1], label: `{${curMatch[1]}}`,  dataType: input.dataType});
  }
  return found;
}

const compileConditionDynamics = (conditions) => {
  let conditionDynamics = []
  for(var i=0; i < (conditions || []).length; i++) {
    let input = { value: `${conditions[i]?.fieldName} ${conditions[i]?.value}`, dataType: 'text' }
    let rsDynamics = getDynamicFields(input)
    conditionDynamics = conditionDynamics.concat(rsDynamics);
  }
  return conditionDynamics
}

const compileInputDynamics = (inputs) => {
  let inputDynamics = []
  for(var i=0; i < (inputs || []).length; i++) {
    let rsDynamics = getDynamicFields(inputs[i])
    inputDynamics = inputDynamics.concat(rsDynamics);
  }
  return inputDynamics
}

export const compileDynamicOptions = (nodes, selectedNode, excludeOutputs = false) => {
  let dynamics = [];
  if(selectedNode > 1) {
    let _nodes = [...nodes];
    _nodes = _nodes.slice(1, selectedNode);
    _nodes.forEach( (_node, index) => {
      let inputDynamics = compileInputDynamics(_node.input)
      let conditionDynamics = compileConditionDynamics(_node.condition)
      dynamics = [...dynamics, ...conditionDynamics, ...inputDynamics]
     
      dynamics = dynamics.filter((dynInput, index, array) => array.findIndex(d => d.key == dynInput.key && d.label == dynInput.label) == index);
      
      if(excludeOutputs === false){
        _node.output.forEach( _output => {
          dynamics.push({key: `step${index+1}_${_output.key}`, label: `(Step${index+1}) ${_output.label}`,  dataType: _output.dataType})
        })
      }
    })
  
  }
  return dynamics;
}