import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import InputIcon from '@material-ui/icons/Input';
import Button from '@material-ui/core/Button';
import { ConnectionConfig } from '../connections/ConnectionConfig';

const useStyles = makeStyles((theme) => ({
  button: {
    "&:disabled": {
      color: 'black',
    },
    boxShadow: 'unset',

  }
}));

/**
 * CreateConnectionButton component
 * 
 * @param { string } hasConnection indicated whether the workspace
 *  already has an active connection form this provider
 * @param { string } provider name of the provider
 * @param { string } connectionUrl oAuth connection URL to the provider endpoint
 */
export const CreateConnectionButton = (props) => {
  const classes = useStyles();
  const renderOauthButton = () => {
    return (
      <div>
        <Button
          href={props.hasConnection ? props.connectionUrl : props.connectionUrl}
          disabled={props.hasConnection}
          variant="contained"
          color="primary"
          size="medium"
          className={classes.button}
          endIcon={props.hasConnection ? <CloudDoneIcon /> : <InputIcon />}
  
        >
          {props.hasConnection ? `${props.provider} Account Is Connected` : `Connect ${props.provider} Account`}
  
        </Button>
      </div>
    );
  }

  const renderApiKeyBox = () => {
    return (
      <ConnectionConfig provider={props.provider}/>
    );
  }

  const renderDigestAuthBox = () => {
    return (
      <div>
        
      </div>
    );
  }

  let content = ''
  switch (props.connectionConfig?.connectionType) {
    case 'oAuth':
      content = renderOauthButton();
      break;
    case 'apiKey':
      content = renderApiKeyBox(props);
      break;
    case 'digest':
      content = renderDigestAuthBox();
      break;
  }
  return content;
}
