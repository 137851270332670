import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { ActionDataSelector } from './ActionDataSelector'
import { ActionDataRule } from './ActionDataRule'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: '100%',
  },
}))

export const ActionCondition = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} className={classes.actionsPane}>
          <ActionDataSelector dynamicFields={props.dynamicFields} updateRunCondition={props.updateRunCondition} runCondition={props.runCondition}/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.actionsPane}>
          <ActionDataRule updateRunCondition={props.updateRunCondition} runCondition={props.runCondition}/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.actionsPane}>
        <TextField label="Value" value={props.runCondition?.[0]?.value || ''} onChange={ (e) => props.updateRunCondition({ value: e.target.value })}/>
        </Grid>
      </Grid>
    </div>
  )
}
