import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { create, show, update, addData, train, predict, list, destroy } from './modelsApi'

const modelsAdapter = createEntityAdapter()

const initialState = modelsAdapter.getInitialState({
  status: 'idle',
  error: null
})
export const listModels = createAsyncThunk(
  'models/list',
  async (data) => {
    const response = await list(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

export const addNewModel = createAsyncThunk('models/addNewModel', async (data) => {
  const response =  await create(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const showModel = createAsyncThunk('models/showModel', async (data) => {
  const response =  await show(data.credentials, data.workspaceId, data.payload.id)
  return response.data?.results
})

export const updateModel = createAsyncThunk('models/updateModel', async (data) => {
  const response =  await update(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const addModelData = createAsyncThunk('models/addModelData', async (data) => {
  const response =  await addData(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const trainModel = createAsyncThunk('models/trainModel', async (data) => {
  const response =  await train(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const predictModel = createAsyncThunk('models/predictModel', async (data) => {
  const response =  await predict(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const deleteModel = createAsyncThunk('models/deleteModel', async (data) => {
  const response =  await destroy(data.credentials, data.workspaceId, data.payload)
  return response.data?.results?.id
})

const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listModels.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listModels.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      modelsAdapter.upsertMany(state, action.payload)
    },
    [listModels.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewModel.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addNewModel.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      modelsAdapter.addOne(state, action.payload)
    },
    [addNewModel.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [updateModel.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateModel.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      modelsAdapter.upsertOne(state, action.payload)
    },
    [updateModel.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [trainModel.pending]: (state, action) => {
      state.status = 'loading';
    },
    [trainModel.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      modelsAdapter.upsertOne(state, action.payload)
    },
    [trainModel.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [addModelData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addModelData.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [addModelData.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [predictModel.pending]: (state, action) => {
      state.status = 'loading';
    },
    [predictModel.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [predictModel.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [deleteModel.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteModel.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      console.log(action.payload)
      modelsAdapter.removeOne(state, action.payload)
    },
    [deleteModel.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
  }
})

//export const { modelAdded } = modelsSlice.actions;
export default modelsSlice.reducer;

export const {
  selectAll: selectAllModels,
  selectById: selectModelById,
  selectIds: selectModelIds,
} = modelsAdapter.getSelectors(state => state.models)