import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router";
import { unwrapResult } from '@reduxjs/toolkit'
import { AppSubLayout } from '../utilities/AppSubLayout'
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'
import { addModelData, selectModelById } from './modelsSlice'
import Session from '../utilities/Session'
import { Notification } from '../utilities/Notification';

const MAX_FILE_SIZE_25_MB = 25000000
const MAX_FILE_COUNT = 1
const ACCEPT_FILE_TYPE = '.csv'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modelListGrid: {
    margin: theme.spacing(3),
    height: '100vh',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(10),
  },
  zeroStateBox: {
    backgroundColor: 'white',
    minHeight: '30vh',
    padding: theme.spacing(5),
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'left',
  },
  baseStyle: {
    flex: 1,
    display: 'inline-table',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    width: '60%',
    height: '30vh',
    transition: 'border .24s ease-in-out',
  },
  activeStyle: {
    borderColor: '#2196f3'
  },
  acceptStyle: {
    borderColor: '#00e676'
  },
  rejectStyle: {
    borderColor: '#ff1744'
  },
  processingStyle: {
    visibility: 'hidden'
  },
  defaultStyle: {

  },
  displayIcon: {
    height: '2em',
    width: '2em',
  },
  textInstructions: {
    color: 'grey',
  },
  uploadConfirmation: {
    fontWeight: 500,
  }
}));



export const ModelDataUpload = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [id, setId] = useState(useParams().modelId)
  const modelStatus = useSelector((state) => state.models.status)
  const model = useSelector((state) => selectModelById(state, id))
  const labelNamesString = (Array.from([...new Set((model?.labels || []).map( x => x.label))])).join(', ')
  
  const [session, setSession] = useState(Session.getSession())
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success' })

  const [dropStyle, setDropStyle] = useState(classes.defaultStyle);
  const [uploadedFiles, setUploadedFiles] = useState([])

  const setDropZoneStyle = (state) => {
    switch(state) {
      case 'accept':
        setDropStyle(classes.acceptStyle)
        break;
      case 'reject':
        setDropStyle(classes.rejectStyle)
        break;
      case 'active':
        setDropStyle(classes.activeStyle)
        break;
      case 'processing':
        setDropStyle(classes.processingStyle)
      default:
        setDropStyle(classes.defaultStyle)
    }
  }

  const handleDrop = (files) => {
    const formData = new FormData();
    formData.append('file', files[0])
    if(uploadData({id, formData})) {
      setUploadedFiles([...uploadedFiles, files?.[0].name])
    }
  }
  const uploadData = async (payload, fileName) => {
    let uploadSuccess = false
    if (modelStatus != 'loading') {
      try {
        const modelUpdate = await dispatch(addModelData({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: payload,
        }))
        unwrapResult(modelUpdate)
        setNotificationConfig({ show: true, notice: 'Uploaded and queued for processing', severity: 'success' })
        uploadSuccess = true  
        setDropZoneStyle('')    
      } catch (e) {
        setDropZoneStyle('reject')
        setNotificationConfig({ show: true, notice: e, severity: 'error' })
      }
    }
    return uploadSuccess
  }

  return (
    <AppSubLayout title={`${model?.name || ''} Data Upload`} workspaceId={session.workspaceId}>
      <Dropzone onDrop={ f => handleDrop(f)} 
        onDragEnter={ _e => setDropZoneStyle('active')}
        onDropAccepted={ _e => setDropZoneStyle('accept')}
        onDropRejected={ _e => setDropZoneStyle('reject')}
        maxFiles={MAX_FILE_COUNT}
        maxSize={MAX_FILE_SIZE_25_MB}
        minSize={1}
        accept={ACCEPT_FILE_TYPE}
        >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={[classes.baseStyle, dropStyle].join(' ')}>
              <input {...getInputProps()} />
              <p className={classes.textInstructions}>Drag and drop a training data file here, or click to select.</p>
              <p className={classes.textInstructions}>You can upload CSV files that are smaller than 25 MB in size. Label column header(s) should match the Data Label value defined in the model configuration (i.e. {labelNamesString}).</p>
              <CloudUploadIcon className={classes.displayIcon}/>
            </div>
          </section>
        )}
      </Dropzone>
      { uploadedFiles.length > 0 &&
        <p className={classes.uploadConfirmation}>
          Successfully uploaded the following files: {uploadedFiles.join(', ')}. <br/>You will get an email notification when the processing is complete.
        </p>
      }
      <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
    </AppSubLayout>
  )
}
