import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { TargetFieldsViewer } from './TargetFieldsViewer'
import { getEnterpriseMetadataTemplates } from '../box/boxApi';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fieldSelectorBox: {
    width: 600,
    minWidth: 600,
    textAlign: 'start',
    overflowY: 'scroll',
    height: '100vh',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  MuiFormControl: {
    root: {
      minWidth: 600,
      marginBottom: 8,
    }
  },
  submitButton: {
    marginBottom: theme.spacing(3),
  },
  fieldEntry: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: '1rem',
  },
  notice: {
    borderRadius: 5,
    backgroundColor: '#f9f9d2',
    padding: 15,
    marginBottom: 10,
  },
}));

const createField = (scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules) => {
  return { scope, sourceName, sourceLabel, fieldLabel, fieldName, fieldType, fieldSelected, rules }
}

const labelFields = (labelConfig) => {
  return labelConfig.map(fo => createField(fo.scope, fo.sourceName, fo.sourceLabel, fo.label, fo.name, fo.dataType, true, fo.rules))
  
}  

export const PreBuiltTargetFieldSelector = (props) => {
  const classes = useStyles();

  const [fieldOptions, setFieldOptions] = useState(labelFields(props.labelConfig || []));
  const [newField, setnewField] = useState('');

  const fieldEntryTemplate = {
    fieldName: '',
    fieldLabel: '',
    sourceName: 'hydra',
    sourceLabel: 'Hydra',
    scope: 'hydra',
    fieldType: 'text',
    rules: [],
    color: 'green',
    fieldSelected: true,
    writeBack: true
  }

  const onFieldNameChanged = (e) => {
    setnewField(e.target.value);
  }
  const onAddFieldClicked = () => {
    let _field = {...fieldEntryTemplate, fieldName: newField, fieldLabel: newField }
    setFieldOptions([...fieldOptions, _field])
    updateLabelConfig([...fieldOptions, _field])
    setnewField('')
  }
  const updateLabelConfig = (fo = fieldOptions) => {
    //if(labelConfigUpdated == true) {
    let _labelConfig = [];
    fo.forEach(option => {
      if (option.fieldSelected == true) {
        _labelConfig.push({
          name: option.fieldName,
          label: option.fieldLabel,
          sourceName: option.sourceName,
          sourceLabel: option.sourceLabel,
          scope: option.scope,
          dataType: option.fieldType,
          rules: option.rules,
          color: 'green',
          writeBack: true
        })
      }
    })
    props.setLabelConfig(_labelConfig)
  }

  return (
    <Box m={0} className={classes.fieldSelectorBox}>
      <Paper className={classes.notice} elevation={0}>This is a pre-built model. You can't change the fields.</Paper>
      <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        
        <TableBody>
          {(props.labelConfig || []).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className={classes.fieldEntry}>
                {row.label}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}
