import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Paper from '@material-ui/core/Paper';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StringDataRule } from './StringDataRule';
import { NumberDataRule } from './NumberDataRule';
import { DateDataRule } from './DateDataRule';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '99%',
  },
  editorPaper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    backgroundColor: '#FCFCFC',
    minWidth: '100%',
  },
  fieldNameAccordian: {
    borderColor: 'lightgrey',
    border: 'solid',
    borderRadius: 5,
    marginBottom: 10,
    borderWidth: 1,
  },
}));

/**
 * TargetFieldsViewer component
 * 
 * @param {*} props 
 */
export const TargetFieldsViewer = (props) => {
  const classes = useStyles();
  
  const handleClick = (_event, index) => {
    const updatedFieldData = Object.assign([...props.fieldData], {
      [index]: {
          ...props.fieldData[index],
          fieldSelected: !(props.fieldData[index].fieldSelected)
      }
    })
    props.setFieldData(updatedFieldData);
    props.setLabelConfigUpdated(updatedFieldData);
  }
  const setFieldRules = (index, rules) => {
    let updatedFieldData = Object.assign([...props.fieldData]);
    updatedFieldData[index].rules = rules;
    props.setFieldData(updatedFieldData);
    props.setLabelConfigUpdated();
  }

  function renderFieldRules(index, fieldName, fieldType, rules) {
    switch (fieldType) {
      case 'text':
        return (
          <StringDataRule fieldIndex={index} fieldName={fieldName} fieldType={fieldType} rules={rules} setFieldRules={setFieldRules} setLabelConfigUpdated={props.setLabelConfigUpdated} />
        )
      case 'number':
        return (
          <NumberDataRule fieldIndex={index} fieldName={fieldName} fieldType={fieldType} rules={rules} setFieldRules={setFieldRules} setLabelConfigUpdated={props.setLabelConfigUpdated}/>
        )
      case 'date':
        return (
          <DateDataRule fieldIndex={index} fieldName={fieldName} fieldType={fieldType} rules={rules} setFieldRules={setFieldRules} setLabelConfigUpdated={props.setLabelConfigUpdated}/>
        )
      default:
        return (
          <StringDataRule fieldIndex={index} fieldName={fieldName} fieldType={fieldType} rules={rules} setFieldRules={setFieldRules} setLabelConfigUpdated={props.setLabelConfigUpdated}/>
        )
    }
    
  }

  const usesValueOptions = () => props.viewerFor === 'mtc'

  const fieldName = (dataField) => {
    return (usesValueOptions() ? dataField?.valueOption : dataField?.fieldName);
  }
  const fieldLabel = (dataField) => {
    return (usesValueOptions() ? dataField?.valueOption : dataField?.fieldLabel);
  }

  return (
    <div className={classes.root}>
      {props.fieldData.map((dataField, index) => {
        return (
          <Accordion key={fieldName(dataField)} elevation={0} className={ usesValueOptions() ? '' : classes.fieldNameAccordian }>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-label="Expand"
              aria-controls="field-information"
              id={`actions-header-${fieldName(dataField)}`}
            >
              <FormControlLabel
                aria-label={`Select field ${fieldLabel(dataField)}`}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox color='primary' checked={dataField.fieldSelected} onChange={(event) => handleClick(event, index)} />}
                label={`${fieldLabel(dataField)} (${dataField.fieldType})`}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
                {renderFieldRules(index, fieldName(dataField), dataField.fieldType, dataField.rules)}
              </Paper>
            </AccordionDetails>
          </Accordion>
    )})}
    </div>
  );
}
