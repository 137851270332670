import React from 'react';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { BoxFolderSelector } from './BoxFolderSelector'
import { HYDRA_API } from '../../configs/env'
import { ModelMinConfidence } from './ModelMinConfidence'
import { ConnectionConfig } from '../connections/ConnectionConfig'

const useStyles = makeStyles((theme) => ({
  basicInfoContainer: {
    textAlign: 'left',
  },
  inputElement: {
    marginBottom: theme.spacing(4),
    minWidth: 400,
  },
}))

const boxTriggerConfig = (props, classes) => {
  return (<Box className={classes.basicInfoContainer}>
    <ConnectionConfig provider={props.source}/>
    { props.accessToken &&
      <Box component={'span'}>
        <Box className={classes.inputElement} />
        <BoxFolderSelector hasConnection={true} triggerConfig={props.triggerConfig} setTriggerConfig={props.setTriggerConfig} boxToken={props.accessToken} />
      </Box>
    }
  </Box>)
}

const minimalConfig = (props, classes) => {
  return (<ModelMinConfidence minConfidence={props.minConfidence} setMinConfidence={props.setMinConfidence}></ModelMinConfidence>)
}

export const ModelBasicInfo = (props) => {
  const classes = useStyles();
  let content;
  switch (props.templateName) {
    case 'boxFormData':
      content = boxTriggerConfig(props, classes);
      break;
    case 'messageClassification':
      content = minimalConfig(props, classes);
      break;
    case 'multiLabelTextClassification':
      content = minimalConfig(props, classes);
      break;
    case 'namedEntityRecognition':
      content = minimalConfig(props, classes);
      break;
    case 'sentimentAnalysis':
      content = minimalConfig(props, classes);
      break;
    case 'commonEntityRecognition':
      content = minimalConfig(props, classes);
      break;
    case 'tabularDataClassification':
      content = minimalConfig(props, classes);
      break;
    case 'industryLabelTagger':
      content = minimalConfig(props, classes);
      break;
    case 'imageTextExtraction':
        content = minimalConfig(props, classes);
        break;
    case 'zoom':
      content = '';
      break;
    default:
      content = '';
  }
  return content;
}