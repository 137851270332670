import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { AppMainLayout } from './AppMainLayout'
import { AccountSettings } from './AccountSettings'
import { UpdatePasswordSettings } from './UpdatePasswordSettings'
import { ApiKeysList } from '../api-keys/ApiKeysList'
import { TeamSettings } from './TeamSettings';
import Session from '../utilities/Session'
import { listUsers } from '../users/usersSlice'
import { listApiKeys } from '../api-keys/apiKeysSlice'
import { Notification } from '../utilities/Notification';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  settingsBox: {
    minHeight: '70vh',
    padding: theme.spacing(3)
  },
  settingsSectionBox: {
    backgroundColor: '#FCFCFC',
    minHeight: '60vh',
    width: '100%',
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const userStatus = useSelector((state) => state.users.status)
  const apiKeyStatus = useSelector((state) => state.apiKeys.status)

  const error = useSelector((state) => state.users.error)
  
  const [value, setValue] = useState(0);
  const [notificationConfig, setNotificationConfig] = useState({ show: false, notice: '', severity: 'success'})

  const [session, setSession] = useState(Session.getSession())

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userStatus === 'idle') {
      dispatch(listUsers({credentials: session.authHeader, workspaceId: session.workspaceId}))
    }
  }, [])

  useEffect(() => {
    if (apiKeyStatus === 'idle') {
      dispatch(listApiKeys({credentials: session.authHeader, workspaceId: session.workspaceId}))
    }
  }, [])

  return (
    <AppMainLayout title='Settings' hideFab={true} workspaceId={session.workspaceId}>
      <Notification notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig} />
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Your Account" {...a11yProps(0)} />
          <Tab label="API Keys" {...a11yProps(1)} />
          <Tab label="Team" {...a11yProps(2)} />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <Paper className={classes.settingsBox} elevation={0}>
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            spacing={3}
          >
            <Grid item xs={12} md={6} className={classes.actionsPane}>
              <Paper className={classes.settingsSectionBox} elevation={0}>
                <AccountSettings notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig}/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} className={classes.actionsPane}>
              <Paper className={classes.settingsSectionBox} elevation={0}>
                <UpdatePasswordSettings notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig}/>
              </Paper>
            </Grid>
          </Grid>
        </Paper>

      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper className={classes.settingsBox} elevation={0}>
          <ApiKeysList apiKeys={[]} notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig}/>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Paper className={classes.settingsBox} elevation={0}>
          <TeamSettings teamMembers={[]} notificationConfig={notificationConfig} setNotificationConfig={setNotificationConfig}/>
        </Paper>
      </TabPanel>
    </AppMainLayout>
  );
}
