import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const show = async (credentials, workspaceId, apiKeyId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/api_keys/${apiKeyId}`, credentials)
  return response
}

export const list = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/api_keys`, credentials)
  return response
}

export const create = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/api_keys`, data, credentials)
  return response
}

export const remove = async (credentials, workspaceId, apiKeyId) => {
  const response = await axios.delete(`${HYDRA_API}/workspaces/${workspaceId}/api_keys/${apiKeyId}`, credentials)
  return response
}

export const update = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/api_keys/${data.id}`, data, credentials)
  return response
}

