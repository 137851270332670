import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { Copyright} from '../utilities/Copyright'
import { processLogin } from './usersSlice'
import { BlockingErrorNotice } from '../utilities/BlockingErrorNotice';
import { errors } from '../../configs/notificationTexts'
import { HydraLogo } from '../utilities/HydraLogo'
import Session from '../utilities/Session'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  blockingErrorNotice: {
    width: '100%',
    backgroundColor: '#efc0c0',
    margin: '10px'
  },
}));

export const UserLogin = () => {
  
  const classes = useStyles();

  const history = useHistory()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginRequestStatus, setLoginRequestStatus] = useState('idle') 
  const [loginFailed, setLoginFailed] = useState(false) 

  const onEmailChanged = (e) => setEmail(e.target.value)
  const onPasswordChanged = (e) => setPassword(e.target.value)
  
  const canLogin =
    [email, password].every(Boolean) && loginRequestStatus === 'idle'

  const onLoginClicked = async () => {
    if(canLogin) {
      try {
        setLoginRequestStatus('pending')
        const resultAction = await dispatch(processLogin({ email, password }))
        unwrapResult(resultAction)
        setEmail('')
        setPassword('')
        history.push(`/workspaces/${Session.getSession().workspaceId}/dashboard`)
      } catch (err) {
        setLoginFailed(true)
        setLoginRequestStatus('idle')
      }
    }
  }
  
  return (
    <Container component="main" maxWidth="xs" style={{ paddingTop: '50px'}}>
      <CssBaseline />
      <HydraLogo/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">Sign in</Typography>
        { loginFailed &&
          <BlockingErrorNotice message={errors.LOGIN_FAILED}/>
        }
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={onEmailChanged}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={onPasswordChanged}
            autoComplete="current-password"
            onKeyPress={ (ev) => 
              {
                if (ev.key === 'Enter') {
                  onLoginClicked()
                }
              }
            }
          />
          
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onLoginClicked} disabled={!canLogin}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/users/request-password-reset" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/users/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )

}