const algoPool = Object.freeze({ commonNER: 'commonNER', sentiment: 'sentiment', textClassification: 'textClassification', ner: 'ner' });

const commonSentimentLabelConfig = [{ name: 'sentiment', label: 'Sentiment', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true }];

const industryTaggerLabelConfig = [{ name: 'label', label: 'Industry Label', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true }];

const commonNerLabelConfig = [{ name: 'PERSON', label: 'Person', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'NORP', label: 'Group', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'FAC', label: 'Building', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'ORG', label: 'Organization', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'GPE', label: 'Countries, cities, states.', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'LOC', label: 'Location', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'PRODUCT', label: 'Product', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'EVENT', label: 'Event', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'WORK_OF_ART', label: 'Creative Work', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'LAW', label: 'Law', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'LANGUAGE', label: 'Language', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'DATE', label: 'Date', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'TIME', label: 'Time', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'PERCENT', label: 'Percent', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'MONEY', label: 'Amount', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'QUANTITY', label: 'Quantity', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'ORDINAL', label: 'Order', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true },
{ name: 'CARDINAL', label: 'Number', dataType: 'text', sourceName: 'hydra', sourceLabel: 'Hydra', scope: 'hydra', rules: [], color: 'green', writeBack: true }]

const templates = [
  { id: 0, name: 'boxFormData', description: 'Extract data from documents and forms in Box using named entity recognition', source: 'box', clfType: 'ner', bannerImage: 'box-form-ner.png', generallyAvailable: false, cost: 3, labelConfig: [], summaryText: 'Please get in touch if you need any help' },
  { id: 1, name: 'messageClassification', description: 'Tag messages with a label based on the message text using text classification', source: 'api', clfType: 'mtc', bannerImage: 'api-message-class.png', generallyAvailable: true, cost: 1, labelConfig: [], summaryText: '' },
  { id: 2, name: 'multiLabelTextClassification', description: 'Tag messages with one or more labels based on the message text using multi-label text classification', source: 'api', clfType: 'mlc', bannerImage: 'api-message-class.png', generallyAvailable: true, cost: 1, labelConfig: [], summaryText: '' },
  { id: 3, name: 'namedEntityRecognition', description: 'Extract data from text inputs using named entity recognition', source: 'api', clfType: 'ner', bannerImage: 'api-doc-ner.png', generallyAvailable: true, cost: 1, labelConfig: [], summaryText: '' },
  { id: 4, name: 'sentimentAnalysis', description: 'Predict whether a text input is positive, negative or neutral using pre-trained sentiment analysis', source: 'api', clfType: 'sen', bannerImage: 'api-sentiment.png', generallyAvailable: true, cost: 1, labelConfig: commonSentimentLabelConfig, summaryText: '' },
  { id: 5, name: 'commonEntityRecognition', description: 'Extract names and places from text inputs using pre-trained named entity recognition', source: 'api', clfType: 'cer', bannerImage: 'api-doc-ner.png', generallyAvailable: true, cost: 1, labelConfig: commonNerLabelConfig, summaryText: '' },
  { id: 6, name: 'tabularDataClassification', description: 'Tag a data input with a label using classification', source: 'api', clfType: 'mdc', bannerImage: 'api-doc-ner.png', generallyAvailable: true, cost: 1, labelConfig: [], summaryText: '' },
  { id: 7, name: 'industryLabelTagger', description: 'Predict an industry label using the company description. This is a pre-trained model', source: 'api', clfType: 'ilc', bannerImage: 'api-industry.png', generallyAvailable: false, cost: 1, labelConfig: industryTaggerLabelConfig, summaryText: '' },
  { id: 8, name: 'imageTextExtraction', description: 'Extract text from images using optical character recognition', source: 'api', clfType: 'ite', bannerImage: 'api-doc-ner.png', generallyAvailable: true, cost: 1, labelConfig: [], summaryText: '' }
]

export { templates }

/*
{ id: 8, name: 'segmentPredictions', description: 'Segment propensity predictions', source: 'segment', clfType: 'tdc', bannerImage: '', generallyAvailable: false, cost: 1, labelConfig: [], summaryText: '' },
{ id: 9, name: 'zendeskTicketClassification', description: 'Zendesk ticket classification', source: 'zendesk', clfType: 'btc', bannerImage: '', generallyAvailable: false, cost: 1, labelConfig: [], summaryText: '' },
{ id: 10, name: 'detectPII', description: 'PII detection', source: 'api', clfType: 'pii', bannerImage: '', generallyAvailable: false, cost: 1 },
*/