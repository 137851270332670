import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { login, logout, signup, listWorkspaceUsers, updateWorkspaceUser, changePassword, inviteWorkspaceUser, resetPassword , generatePasswordReset} from './usersApi'

import Session from '../utilities/Session'
import { formatApiError } from '../utilities/apiUtils'

const manageLocalSession = (token, workspaceId, userId) => Session.setSession({ token, workspaceId, userId })

const usersAdapter = createEntityAdapter()

//const initialState = usersAdapter.getInitialState()
const initialState = usersAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const processLogin = createAsyncThunk(
  'users/processLogin',
  async (credentials) => {
    const response = await login(credentials)
    manageLocalSession(response?.data?.token, response?.data?.primaryWorkspace, response?.data?.id)
    return response.data
  }
)

export const processLogout = createAsyncThunk('users/processLogout', async (data) => {
  const response =  await logout(data.credentials, data.workspaceId, data.payload)
  manageLocalSession(response?.data?.token, response?.data?.primaryWorkspace, response?.data?.id)
  console.log(response?.data)
  return response.data?.results?.id
})

export const processSignup = createAsyncThunk(
  'users/signup',
  async (data, { rejectWithValue }) => {
    try {
      const response = await signup(data)
      manageLocalSession(response?.data?.token, response?.data?.primaryWorkspace)
      return response.data
    } catch(e) {
      return rejectWithValue(formatApiError(e.response?.data))
    }
  }
)

export const requestPasswordReset = createAsyncThunk(
  'users/requestPasswordReset',
  async (data, { rejectWithValue }) => {
    try {
      const response = await generatePasswordReset(data)
      return response.data
    } catch(e) {
      return rejectWithValue(formatApiError(e.response?.data))
    }
  }
)

export const processPasswordReset = createAsyncThunk(
  'users/passwordReset',
  async (data, { rejectWithValue }) => {
    try {
      const response = await resetPassword(data)
      return response.data
    } catch(e) {
      return rejectWithValue(formatApiError(e.response?.data))
    }
  }
)

export const listUsers = createAsyncThunk(
  'users/list',
  async(data) => {
    const response = await listWorkspaceUsers(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

export const updateUserInfo = createAsyncThunk(
  'users/updateUserInfo',
  async(data) => {
    const response = await updateWorkspaceUser(data.credentials, data.workspaceId, data.userId, data.payload)
    return response.data?.results
  }
)

export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async(data) => {
    const response = await changePassword(data)
    return response.data?.results
  }
)

export const inviteUser = createAsyncThunk(
  'users/inviteUser',
  async(data) => {
    const response = await inviteWorkspaceUser(data.credentials, data.workspaceId, data.payload)
    return response.data?.results
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [processLogin.fulfilled]: usersAdapter.addOne,
    [processSignup.fulfilled]: usersAdapter.addOne,
    [processSignup.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [listUsers.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listUsers.fulfilled]: (state, action) => { 
      state.status = 'succeeded';
      usersAdapter.upsertMany(state, action.payload);
    },
    [listUsers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [updateUserInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateUserInfo.fulfilled]: (state, action) => { 
      state.status = 'succeeded';
      usersAdapter.upsertMany(state, action.payload);
    },
    [updateUserInfo.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [updatePassword.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updatePassword.fulfilled]: (state, action) => { 
      state.status = 'succeeded';
    },
    [updatePassword.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [inviteUser.pending]: (state, action) => {
      state.status = 'loading';
    },
    [inviteUser.fulfilled]: (state, action) => { 
      state.status = 'succeeded';
      usersAdapter.upsertMany(state, action.payload);
    },
    [inviteUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [processLogout.pending]: (state, action) => {
      state.status = 'loading';
    },
    [processLogout.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      usersAdapter.removeOne(state, action.payload)
    },
    [processLogout.rejected]: (state, action) => {
      state.status = 'failed';
    },
  }
})

export default usersSlice.reducer

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors(state => state.users)