import { configureStore } from '@reduxjs/toolkit';
import modelsReducer from '../features/models/modelsSlice'
import automationsReducer from '../features/automations/automationsSlice'
import usersReducer from '../features/users/usersSlice'
import apiKeysReducer from '../features/api-keys/apiKeysSlice'
import workspacesReducer from '../features/workspaces/workspacesSlice'
import reportsReducer from '../features/reports/reportsSlice'
import connectionsReducer from '../features/connections/connectionsSlice'

export default configureStore({
  reducer: {
    users: usersReducer,
    models: modelsReducer,
    automations: automationsReducer,
    apiKeys: apiKeysReducer,
    workspaces: workspacesReducer,
    reports: reportsReducer,
    connections: connectionsReducer,
  },
});
