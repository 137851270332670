import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { processLogout } from './usersSlice'
import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

import Session from '../utilities/Session'

const useStyles = makeStyles((theme) => ({
}))

export const UserLogout = () => {
  const classes = useStyles();

  const history = useHistory()
  const dispatch = useDispatch()

  const [logoutRequestStatus, setLogoutRequestStatus] = useState('idle') 
  const [logoutFailed, setLogoutFailed] = useState(false) 
  
  const canLogout = (logoutRequestStatus !== 'pending')

  const [session, setSession] = useState(Session.getSession())

  if(canLogout) {
    try {
      setLogoutRequestStatus('pending')
      const logout = dispatch(processLogout({
        credentials: session.authHeader,
        workspaceId: session.workspaceId,
        payload: {}
      })).then( (value) => {
        unwrapResult(logout)
        history.push(`/`)
      })

    } catch (err) {
      setLogoutFailed(true)
      setLogoutRequestStatus('idle')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

      </div>
    </Container>)
}