import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  ruleFormControl: {
    marginBottom: theme.spacing(0),
    minWidth: 380,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ruleSelectorBox: {
    width: 400,
    minWidth: 400,
    marginTop: 15,
    paddingBottom: 10,
    textAlign: 'start',
  },

}));

/**
 * String data rule component
 * 
 * @param {*} props 
 */
export const StringDataRule = (props) => {
  const classes = useStyles();
  const [rule, setRule] = React.useState(props.rules[0] || {matcher: 'none', value: ''} );

  const handleMatcherChange = (event) => {
    setRule({ matcher: event.target.value, value: rule.value});
    props.setFieldRules(props.fieldIndex, [{matcher: event.target.value, value: rule.value }]);
    props.setLabelConfigUpdated();
  };

  const handleRuleValue = (event) => {
    setRule({ matcher: rule.matcher, value: event.target.value});
    props.setFieldRules(props.fieldIndex, [{ matcher: rule.matcher, value: event.target.value }])
    props.setLabelConfigUpdated();
  }

  return (
    <Box m={1}>
      <p>Create a helper rule</p>
      <FormControl className={classes.ruleFormControl}>
      
        <InputLabel id={`${props.fieldName}-rule-matcher`}>Matcher</InputLabel>
        <Select
          id={`${props.fieldName}-rule-matcher-selector`}
          value={rule.matcher}
          onChange={handleMatcherChange}
          className={classes.ruleCondtionSelector}
        >
          <MenuItem value='none'>No Rule</MenuItem>
          <MenuItem value='startsWith'>Starts with</MenuItem>
          <MenuItem value='endsWith'>Ends with</MenuItem>
          <MenuItem value='contains'>Contains</MenuItem>
          <MenuItem value='matches'>Matches</MenuItem>
          <MenuItem value='regex'>Regex</MenuItem>
        </Select>
        <br/>
        <TextField id={`${props.fieldName}-rule-text`} label="Text" value={rule.value} onChange={ handleRuleValue } />
      </FormControl>
    </Box>
  );
}
