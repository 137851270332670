import React, { useState, useEffect } from 'react';

import { ActionConfig } from './ActionConfig'
import { ActionStartConfig } from './ActionStartConfig'
import { ActionTemplates } from './ActionTemplates';
import { compileDynamicOptions } from '../utilities/dynamicFieldUtils'

export const ActionEditor = (props) => {
  
  const [startVars, setStartVars] = useState([]);
  
  useEffect(() => {
    let fieldsList = compileDynamicOptions(props.nodes, props.nodes.length, true)
    let varOptions = fieldsList.map(f => ({ 'name': f.label, 'value': ''}))
    setStartVars(varOptions);
  }, [props.nodes])

  if (props.nodes[props.selectedNode].type === 'start') {
    
    return <ActionStartConfig startVars={startVars} setStartVars={setStartVars} showButtons={true} testAutomation={props.testAutomation}/>

  } else if(props.nodes[props.selectedNode].actionName === ''){
    return <ActionTemplates actionTemplates={props.actionTemplates} nodes={props.nodes} selectedNode={props.selectedNode} setNodes={props.setNodes}/>
    
  } else {
    return <ActionConfig actionTemplates={props.actionTemplates} nodes={props.nodes} selectedNode={props.selectedNode} setSelectedNode={props.setSelectedNode} setNodes={props.setNodes}/>
  
  }

}