import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  selectionContainer: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
  table: {
    width: 400,
  },
  iconColumn: {
    width: 10,
    paddingRight: 0,
    color: 'blue',
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: '#18A0FB12',
    },
    selected: {
      backgroundColor: '#18A0FB12',
    }
  }
});

/**
 * Renders a list of items available for selection
 * 
 * @param {*} props 
 */
export const DataFilterSelectionList = (props) => {
  const classes = useStyles();
  const handleClick = (event, key, value, label) => {
    let data = { key, value, label };
    const selectedIndex = props.selected.map( e => e.value ).indexOf(value);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1),
      );
    }
    props.setSelected(newSelected);
  };
  const isSelected = (value) => (props.selected || []).map( e => e.value).indexOf(value) !== -1;
  
  return (
    <TableContainer component={Paper} elevation={0} className={classes.selectionContainer}>
      <Table className={classes.table} aria-label="Data filter selection list table">
        <TableBody>
          { (props.listData || []).length === 0 ? 
            <TableRow key={'100'}>
              <TableCell component="th" scope="row">
                No results. Try a different search query
              </TableCell>
            </TableRow>
            :
            props.listData.map((row, index) => {
              const isItemSelected = isSelected(row.value);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  classes={{ selected: classes.selected }}
                  className={classes.tableRow}
                  onClick={(event) => handleClick(event, row.key, row.value, row.label)}
                  aria-checked={isItemSelected}
                  role='checkbox'
                  key={row.value}
                  tabIndex={-1}
                  selected={isItemSelected}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell size='small' className={classes.iconColumn}>
                    {props.listIcon}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
