import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteApiKey } from '../api-keys/apiKeysSlice'
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  settingsSectionBox: {
    backgroundColor: 'white',
    minHeight: '10vh',
    width: '100%',
    marginBottom: 30,
  },
  keyPanel: {
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingTop: 20,
  },
  digest: {
    overflowWrap: 'anywhere',
    fontSize: '0.9em',
    backgroundColor: '#FCFCFC',
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  expiresAt: {
    fontSize: '0.9em',
  }
}));

export const ApiKey = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
 
  const apiKeyStatus = useSelector((state) => state.apiKeys.status)
  const [updateFailed, setUpdateFailed] = useState(false)

  const [session, setSession] = useState(Session.getSession())
 
  const canUpdate = (apiKeyStatus != 'loading')


  const handleDeleteApiKeyClick = async (keyId) => {
    if(canUpdate) {
      try {
        setUpdateFailed(false)
        const deleteKey = await dispatch(deleteApiKey({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          apiKeyId: keyId,
        }))
        unwrapResult(deleteKey);
        props.setNotificationConfig({show: true, notice: 'API key deleted', severity: 'success'})
      } catch (err) {
        setUpdateFailed(true)
        console.log(err);
        props.setNotificationConfig({show: true, notice: 'Something went wrong', severity: 'error'})
      }
    }
  }

  const formatTime = (seconds) => seconds ? `Expires on ${(new Date(seconds * 1000).toLocaleString('en-US'))}` : ''

  return (

      <Paper className={classes.settingsSectionBox} elevation={0} key={props.id}>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography style={{ paddingTop: '10px' }}>
              { props.name }
            </Typography>
            <Typography style={{ paddingTop: '10px' }} className={classes.expiresAt}>
              {formatTime(props?.expiresAt?._seconds)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} className={classes.keyPanel}>
            <Typography style={{ paddingTop: '10px' }} className={classes.digest}>
              { props.digest }
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
          <IconButton aria-label="delete" onClick={ (_e) => handleDeleteApiKeyClick(props.id)}>
            <DeleteIcon />
          </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20 }}/>
      </Paper>
  )
}