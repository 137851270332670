import React from 'react';
import { SvgIcon, makeStyles } from '@material-ui/core';
import { ReactComponent as ModelSvg } from '../../icons/brain-filled.svg';

const useStyles = makeStyles((theme) => ({
  mySvgStyle:{
    fillColor: theme.palette.primary.main
  }
}))

export const ModelIcon = () => {
  const classes = useStyles();
  return(
    <SvgIcon className={classes.mySvgStyle} viewBox="0 0 512 512">
      <ModelSvg/>
    </SvgIcon>
  )
}