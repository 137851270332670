import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmailIcon } from '../utilities/EmailIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { processSignup } from './usersSlice'
import { BlockingErrorNotice } from '../utilities/BlockingErrorNotice';
import { errors } from '../../configs/notificationTexts'
import { Copyright} from '../utilities/Copyright'
import { validations, validateField } from '../utilities/validate'
import { HydraLogo } from '../utilities/HydraLogo'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '80vh',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  successIconBackground: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
  },
  successMessageBox: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(5),
    fontSize: '1.2rem',
    lineHeight: '2rem',
    backgroundColor: 'white',
    borderRadius: 5,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUp = () => {
  const classes = useStyles();

  const history = useHistory()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true) 
  const [password, setPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(true)
  const [signupRequestStatus, setSignupRequestStatus] = useState('idle') 
  const [signupFailed, setSignupFailed] = useState(false)
  const error = useSelector((state) => state.users.error)

  const canSignup =
    [email, password, firstName, lastName].every(Boolean) && signupRequestStatus === 'idle' && isValidPassword
  
  const onFirstNameChanged = (e) => setFirstName(e.target.value)
  const onLastNameChanged = (e) => setLastName(e.target.value)
  const onEmailChanged = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(validateField(e.target.value,
      validations.EMAIL))
  }
  const onPasswordChanged = (e) => {
    setPassword(e.target.value)
    setIsValidPassword(validateField(e.target.value,
      validations.PASSWORD))
    
  }
  const onSignupClicked = async () => {
    if(canSignup) {
      try {
        setSignupRequestStatus('pending')
        const resultAction = await dispatch(processSignup({ firstName, lastName, email, password }))
        unwrapResult(resultAction)
        setFirstName('')
        setLastName('')
        setEmail('')
        setPassword('')
        setSignupRequestStatus('succeeded')
      } catch (err) {
        setSignupRequestStatus('idle')
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs" style={{ paddingTop: '50px'}}>
      <CssBaseline />
      <HydraLogo/>
      { (signupRequestStatus === 'succeeded') &&
        <div className={classes.paper}>
          <Avatar className={classes.successIconBackground}>
          <EmailIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Check your email
          </Typography>
          <Box className={classes.successMessageBox}>
            You are almost there. We sent you an activation link. Check your email and click on the activation email to complete the set-up.
          </Box>
          
        </div>
      }
      { (signupRequestStatus !== 'succeeded') &&
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          { error &&
            <BlockingErrorNotice message={(error || []).join() || errors.SIGNUP_FAILED}/>
          }
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={onFirstNameChanged}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={onLastNameChanged}
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!isValidEmail && email !== ''}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={onEmailChanged}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!isValidPassword && password !== ''}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={onPasswordChanged}
                  autoComplete="current-password"
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSignupClicked} disabled={!canSignup}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/users/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      }
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}