import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AppMainLayout } from '../utilities/AppMainLayout'
import { ActionElement } from '../actions/ActionElement'
import IconButton from '@material-ui/core/IconButton'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Session from '../utilities/Session'
import { useHistory } from 'react-router-dom';

import {
  listAutomations,
  selectAllAutomations,
} from './automationsSlice'

const useStyles = makeStyles((theme) => ({
  table: {

  },
  automationsTablePane: {
    height: '100vh',
    overflowY: 'scroll',
  },
  previewPane: {
    backgroundColor: 'white',
    height: '100vh',
    marginTop: theme.spacing(1),
    padding: theme.spacing(5),
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: '#18A0FB12',
    },
    selected: {
      backgroundColor: '#18A0FB12',
    }
  },
  tableCellActionIcon: {
    float: 'right',
  },
  zeroStateBox: {
    backgroundColor: 'white',
    minHeight: '30vh',
    padding: theme.spacing(5),
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'left',
  },
  automationLabel: {
    fontSize: '1rem',
  }
}))

export const AutomationsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const automations = useSelector((state) => selectAllAutomations(state))
  const automationStatus = useSelector((state) => state.automations.status)
  const error = useSelector((state) => state.automations.error)

  const [selected, setSelected] = useState(0);
  const [previewContent, setPreviewContent] = useState('');

  const handleSelection = (automationId) => {
    setSelected(automationId);
    let selectedAutomation = automations.find(a => a.id === automationId);
    setPreviewContent(generatePreview(selectedAutomation));
  }

  const generatePreview = (automation) => {
    let previewContent = (automation.nodes || []).map((node, index) => (
      <ActionElement key={index} actionName={node.actionName} actionDescription={node.actionDescription} actionLogo={''} previewMode={true} hideTrail={automation.nodes.length === (index + 1)} />
    ))
    return previewContent;
  }

  const [session, setSession] = useState(Session.getSession())
  useEffect(() => {
    if (automationStatus === 'idle') {
      dispatch(listAutomations({ credentials: session.authHeader, workspaceId: session.workspaceId }))
    }
  }, [])

  let listContent;
  let preview;
  let content;

  if (automationStatus === 'succeeded') {
    listContent = automations.map((automation) => (
      <TableRow key={automation.id} hover={true} selected={automation.id === selected} className={classes.tableRow}>
        <TableCell onClick={() => handleSelection(automation.id)} component="th" scope="row" className={classes.automationLabel}>
          {automation.name}
          <IconButton onClick={(e) => history.push(`/workspaces/${session.workspaceId}/automations/${automation.id}`)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.tableCellActionIcon}>
            <EditTwoToneIcon />
          </IconButton>
        </TableCell>

      </TableRow>
    ))

  } else if (automationStatus === 'failed') {
    listContent = <div>{error}</div>
  }

  if (automations.length > 0) {
    preview = generatePreview(automations[0]);
  }

  const automationsZeroState = () => {
    return (
      <Grid container style={{ minHeight: '90vh' }} spacing={0}
        direction="column"
        alignItems="center"
        justify="center">
        <Grid item xs={4} className={classes.zeroStateBox}>
          <Box>
            Automations take in model predictions and event data as input and run tasks in other apps. <br /><br />You don't have any automations yet. Try creating one...
        </Box>
        </Grid>
      </Grid>
    )
  }
  const automationsGrid = () => {
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: '24px' }}>
        <Grid item xs={12} md={5} className={classes.automationsTablePane}>

          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table" elevation={0}>
              <TableBody elevation={0}>
                {listContent}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={7} className={classes.previewPane}>


          <Box style={{ paddingLeft: '30%', paddingRight: '30%', }}>
            {previewContent || preview}
          </Box>


        </Grid>
      </Grid>
    );
  }

  if (automations.length > 0) {
    content = automationsGrid();
  } else if(automationStatus !== 'loading') {
    content = automationsZeroState();
  }

  return (
    <AppMainLayout title='Automations' workspaceId={session.workspaceId} fabLink={'/automations/new'}>
      {content}
    </AppMainLayout>
  );
}
