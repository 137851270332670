import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

import { addNewApiKey } from './apiKeysSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  settingsSectionBox: {
    backgroundColor: 'white',
    minHeight: '10vh',
    width: '100%',
    marginBottom: 30,
  },
  iconButtonContainer: {
    paddingTop: theme.spacing(3)
  },
  hidden: {
    visibility: 'hidden',
  },
  apiKeyName: {
    width: '100%',
  },
}));

const generateDigest = (length = 32) => {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_!',
      digest = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
      digest += charset.charAt(Math.floor(Math.random() * n));
  }
  return digest;
}

export const AddApiKey = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [session, setSession] = useState(Session.getSession())
  const apiKeyStatus = useSelector((state) => state.apiKeys.status)
  
  const [digest, setDigest] = useState(generateDigest())
  const [name, setName] = useState('')
  const [updateFailed, setUpdateFailed] = useState(false)

  const onNameChanged = (e) => setName(e.target.value)
  
  const canUpdate =
    [name].every(Boolean) && apiKeyStatus != 'loading'
  
  const handleAddApiKeyClick = async () => {
    if(canUpdate) {
      try {
        setUpdateFailed(false)
        const addApiKey = await dispatch(addNewApiKey({
          credentials: session.authHeader,
          workspaceId: session.workspaceId,
          payload: { name: name, digest: digest },
        }))
        unwrapResult(addApiKey);
        setName('');
        props.setShowAddApiKeyForm(false);
        props.setNotificationConfig({show: true, notice: 'New API key added', severity: 'success'})
      } catch (err) {
        setUpdateFailed(true)
        console.log(err);
        props.setNotificationConfig({show: true, notice: 'Something went wrong', severity: 'error'})
      }
    }
  }

  return (

    <Paper className={classes.settingsSectionBox} elevation={0} key={0}>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        spacing={3}>
        <Grid item xs={12} md={7}>
          <TextField
            margin="normal"
            required
            id="apiKeyName"
            label="API Key Name"
            name="firstName"
            autoComplete="API Key Name"
            className={classes.apiKeyName}
            value={name}
            onChange={onNameChanged}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            margin="normal"
            required
            id="digest"
            label="Key"
            name="digest"
            autoComplete="Digest"
            value={digest}
            className={classes.hidden}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={1} className={classes.iconButtonContainer} style={{ paddingTop: 24}}>
          <IconButton aria-label="add api key" onClick={(_e) => handleAddApiKeyClick()}>
            <SaveIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20 }} />
    </Paper>
  )
}