import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { BlockingErrorNotice } from './BlockingErrorNotice';
import { errors } from '../../configs/notificationTexts'
import Session from '../utilities/Session'
import { unwrapResult } from '@reduxjs/toolkit'

import { selectUserById, updatePassword } from '../users/usersSlice'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    padding: theme.spacing(3),
  },
  form: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
    '& .MuiFormControl-root': {
      minWidth: '90%',
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * Update password settings component
 * 
 */
export const UpdatePasswordSettings = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [session, setSession] = useState(Session.getSession())
  const user = useSelector( state => selectUserById(state, session.userId));
  const userStatus = useSelector((state) => state.users.status)
 

  const [email, setEmail] = useState(user?.email || '')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [updateFailed, setUpdateFailed] = useState(false)

  const onCurrentPasswordChanged = (e) => setCurrentPassword(e.target.value)
  const onNewPasswordChanged = (e) => setNewPassword(e.target.value)
  const onConfirmNewPasswordChanged = (e) => setConfirmNewPassword(e.target.value)

  const canUpdate =
    [currentPassword, newPassword, confirmNewPassword].every(Boolean) && (newPassword === confirmNewPassword) && userStatus != 'loading' && email

  const onUpdateClicked = async () => {
    if(canUpdate) {
      try {
        setUpdateFailed(false)
        /**
         * process the update request here
         */
        const userUpdate = await dispatch(updatePassword({
            email: email,
            password: currentPassword,
            newPassword: newPassword,
        }))
        unwrapResult(userUpdate);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        props.setNotificationConfig({show: true, notice: 'Password updated', severity: 'success'});
      } catch (err) {
        setUpdateFailed(true)
        console.log(err);
        props.setNotificationConfig({show: true, notice: 'Something went wrong', severity: 'error'});
      }
    }
  }

  useEffect(() => {
    setEmail(user?.email || '');
  }, [user])

  return (
    <Box component='span'>
      <Typography className={classes.sectionTitle}>
        Change Password
                </Typography>
        { updateFailed &&
          <BlockingErrorNotice message={errors.PASSWORD_UPDATE_FAILED}/>
        }
      <form className={classes.form} noValidate>
        <TextField
          margin="normal"
          required
          id="currentPassword"
          value={currentPassword}
          label="Current Password"
          name="currentPassword"
          type="password"
          onChange={onCurrentPasswordChanged}
          autoComplete="Current Password"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          name="newPassword"
          value={newPassword}
          label="New Password"
          type="password"
          id="newPassword"
          onChange={onNewPasswordChanged}
          autoComplete="New Password"
        />
        <TextField
          margin="normal"
          required
          name="confirmNewPassword"
          value={confirmNewPassword}
          label="Confirm New Password"
          type="password"
          id="confirmNewPassword"
          onChange={onConfirmNewPasswordChanged}
          autoComplete="Confirm New Password"
        />

        <Button
          type="button"
          color="primary"
          className={classes.submitButton}
          onClick={onUpdateClicked}
          disabled={!canUpdate}
        >
          Update
          </Button>
      </form>
    </Box>
  )
}