import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Session from '../utilities/Session'
import { listReports, selectAllReports, selectReportById } from './reportsSlice'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

import { AppMainLayout } from '../utilities/AppMainLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
  chartsContainer: {
    margin: theme.spacing(3),
  },
  chartTitle: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    fontWeight: 500,
  },
  meterText: {
    fontWeight: 400,
  }
}))

/**
 * Dashboard component
 * 
 */
export const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const systemReport = useSelector(state => selectReportById(state, 'system_report'));
  const reportStatus = useSelector((state) => state.reports.status)
  const error = useSelector((state) => state.reports.error)

  const [session, setSession] = useState(Session.getSession())

  useEffect(() => {
    if (reportStatus === 'idle') {
      dispatch(listReports({ credentials: session.authHeader, workspaceId: session.workspaceId }))
    }
  }, [])


  const chartLines = (data) => {
    if (data.length == 0) { data = emptyLineChartData()}
    const allModelNames = data.map( e => Object.keys(e))
      .reduce((a, c) => a.concat(c))
      .filter(x => x !== 'eventDate')
    return [...new Set(allModelNames)]
  }

  const chartLineHeaders = (headerNames) => {
    const headers = headerNames.map(c =>
      <Line key={c} type="monotone" dataKey={c} stroke={'#'+Math.floor(Math.random()*16777215).toString(16)} />
    )
    return headers;
  }

  const chartDates = () => {
    let dates = [];
    [...Array(30).keys()].reverse().forEach( e => {
      let date = new Date();
      date.setDate(date.getDate() - e);
      let dateString =  `${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
      dates.push(dateString)
    })
    return dates;
  }

  const emptyLineChartData = (dates = chartDates()) => {
    let emptyData = [];
    dates.forEach(e => {
      emptyData.push({ eventDate: e, count: 0})
    })
    return emptyData;
  }

  const formattedChartData = (lines, rawData) => {
    if (rawData.length === 0) { 
      return emptyLineChartData();
    }
    let output = [];
    rawData.forEach( e => {
      const diff = lines.filter(x => !(Object.keys(e)).includes(x));
      if(diff.length > 0) {
        const diffEntries = diff.map( d => ({ [d]: 0})).reduce((a, c) => ({...a, ...c}));
        output.push({...e, ...diffEntries});
      } else {
        output.push({...e});
      }
    })
    return output;
  }
  
  const predictedLines = chartLines(systemReport?.predictedEvents || []);
  const predictedLineHeaders = chartLineHeaders(predictedLines);
  const predictedEventsChartData = formattedChartData(predictedLines, (systemReport?.predictedEvents || []))

  const trainedLines = chartLines(systemReport?.trainEvents || []);
  const trainedLineHeaders = chartLineHeaders(trainedLines);
  const trainedEventsChartData = formattedChartData(trainedLines, (systemReport?.trainEvents || []))

  return (
    <AppMainLayout title={'Dashboard'} hideFab={true} workspaceId={session.workspaceId}>
      <Box className={classes.chartsContainer}>
        <Grid container spacing={3} style={{ width: '100%' }}>
          <Grid item xs={12} md={12} className={classes.actionsPane}>
          </Grid>
          <Grid item xs={12} md={6} className={classes.actionsPane}>
            <Paper elevation={0} variant='outlined'>
              <Typography className={classes.chartTitle}>Prediction Events (30 days)</Typography>
              <LineChart width={window.innerWidth * 0.4} height={300} data={predictedEventsChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                {predictedLineHeaders}
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="eventDate" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className={classes.actionsPane}>
            <Paper elevation={0} variant='outlined'>
              <Typography className={classes.chartTitle}>Train Events (30 days)</Typography>
              <LineChart width={window.innerWidth * 0.4} height={300} data={trainedEventsChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                {trainedLineHeaders}
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="eventDate" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ width: '100%', marginTop: '20px' }}>
          <Grid item xs={12} md={4} className={classes.actionsPane}>
            <Paper elevation={0} variant='outlined'>
              <Typography className={classes.chartTitle}>Active Models</Typography>
              <Typography component={'span'} variant={'h1'} className={classes.meterText}>{systemReport?.activeModelsCount || 0}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className={classes.actionsPane}>
            <Paper elevation={0} variant='outlined'>
              <Typography className={classes.chartTitle}>Total Events Processed (30 days)</Typography>
              <Typography component={'span'} variant={'h1'} className={classes.meterText}>{systemReport?.eventsCount || 0}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className={classes.actionsPane}>
            <Paper elevation={0} variant='outlined'>
              <Typography className={classes.chartTitle}>hpu Consumption (30 days)</Typography>
              <Typography component={'span'} variant={'h1'} className={classes.meterText}>{systemReport?.hpuCost || 0}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </AppMainLayout >
  )

}
