import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
   
  },
  actionElementContainer: {
    
  },
  downArrow: {
    color: 'grey',
  }
}))

export const ActionElement = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.actionElementContainer}>
      <CardActionArea disabled={ props.previewMode || false} onClick={ () => props.setSelectedNode(props.actionId)}>
      <Card className={classes.paper} variant={'outlined'}>
          <CardContent>
            {props.actionDescription}
          </CardContent>  
      </Card>
      </CardActionArea>
      { !props.hideTrail &&
        <MoreVertIcon className={classes.downArrow}/>
      }
    </Box>
  )
}