import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { list, show } from './workspacesApi'

const workspacesAdapter = createEntityAdapter()

const initialState = workspacesAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const showWorkspace = createAsyncThunk(
  'workspaces/show',
  async (data) => {
    const response = await show(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

export const listWorkspaces = createAsyncThunk(
  'workspaces/list',
  async (data) => {
    const response = await list(data.credentials)
    return response.data?.results
  }
)

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listWorkspaces.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listWorkspaces.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      workspacesAdapter.upsertMany(state, action.payload)
    },
    [listWorkspaces.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [showWorkspace.pending]: (state, action) => {
      state.status = 'loading';
    },
    [showWorkspace.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      workspacesAdapter.upsertMany(state, action.payload)
    },
    [showWorkspace.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  }
})

//export const { modelAdded } = modelsSlice.actions;
export default workspacesSlice.reducer;

export const {
  selectAll: selectAllWorkspaces,
  selectById: selectWorkspaceById,
  selectIds: selectWorkspaceIds,
} = workspacesAdapter.getSelectors(state => state.workspaces)
