import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { PrivateRoute } from './features/utilities/PrivateRoute'

import logo from './logo.svg';
import './App.css';
import { UserLogin } from './features/users/UserLogin';
import { SignUp} from './features/users/SignUp'
import { ModelsList } from './features/models/ModelsList';
import { ModelDataUpload } from './features/models/ModelDataUpload';
import { ModelPredictionPreview } from './features/models/ModelPredictionPreview';
import { ModelBuilderWizard } from './features/utilities/ModelBuilderWizard'
import { AutomationBuilder } from './features/automations/AutomationBuilder'
import { AutomationsList } from './features/automations/AutomationsList';
import { Settings } from './features/utilities/Settings'
import { Dashboard } from './features/reports/Dashboard'
import { UserLogout } from './features/users/UserLogout'
import { PasswordReset } from './features/users/PasswordReset'
import { RequestPasswordReset } from './features/users/RequestPasswordReset'

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={UserLogin} />
          <Route exact path="/users/signup" component={SignUp} />
          <Route exact path="/users/login" component={UserLogin} />
          <Route exact path="/users/reset-password" component={PasswordReset} />
          <Route exact path="/users/request-password-reset" component={RequestPasswordReset} />
          <PrivateRoute exact path="/users/logout" component={UserLogout} />
          <PrivateRoute path="/workspaces/:workspaceId/models/new">
            <ModelBuilderWizard/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/models/:modelId/predictions">
            <ModelPredictionPreview/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/models/:modelId/data">
            <ModelDataUpload/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/models/:modelId">
            <ModelBuilderWizard/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/models">
            <ModelsList/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/automations/new">
            <AutomationBuilder/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/automations/:automationId">
            <AutomationBuilder/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/automations">
            <AutomationsList/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/settings">
            <Settings/>
          </PrivateRoute>
          <PrivateRoute path="/workspaces/:workspaceId/dashboard">
            <Dashboard/>
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
