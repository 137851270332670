import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '60%',
    marginBottom: 30,
  },
  editorPaper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    margin: theme.spacing(2),
    backgroundColor: '#FCFCFC',
  },
  actionButton: {
    marginTop: 20,
    marginRight: 10,
  }
}))

export const ActionStartConfig = (props) => {
  const classes = useStyles();
  
  const handleVarFieldChange = (event) => {
    const _varFields = [...props.startVars]
    _varFields[event.target.dataset.id]['value'] = event.target.value
    props.setStartVars(_varFields)
  };

  const clearData = (event) => {
    let _varFields = [...props.startVars]
    _varFields = _varFields.map( v => ({ name: v.name, value: '' }) )
    props.setStartVars(_varFields)
  }

  const testAutomation = (event) => {
    const launchVars = props.startVars.map( e => ({ [(e.name.replace('{', '').replace('}', ''))]: e.value}))
      .reduce((a, c) => ({ ...a, ...c }));
    props.testAutomation(launchVars)
  }

  return (
    <Box component={'span'}>
      <CssBaseline />
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Automation Variables
      </Typography>
      <br/>
      { props.startVars.map((item, index) => 
        <TextField id={`${index}`} key={index} inputProps={{ 'data-id': index }} name={item.name} label={item.name} value={item.value} classes={{root: classes.root}} onChange={ handleVarFieldChange }/>
      )}

      { props.showButtons
        ? <Box>
            <Button variant="outlined" className={classes.actionButton} onClick={ clearData }>Clear data</Button>
            <Button variant="outlined" className={classes.actionButton} onClick={ testAutomation } color="primary">Test Automation</Button>
          </Box>
        : ''
      }
      </Paper>
    </Box>
  )
}