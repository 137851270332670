import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AssesmentIcon from '@material-ui/icons/Assessment'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    minWidth: 300,
    height: 250,
    margin: '10px',
    backgroundColor: '#FCFCFC',

  },
  media: {
    height: 80,
    backgroundSize: 'fill',
  },
  cardActions: {
    display: 'flow-root',
    textAlign: 'end',
  },
  cardDescription: {
    height: 100,
    minHeight: 100,
    overflow: 'hidden',
    '& p': {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textAlign: 'left',
      color: 'black',
      fontWeight: 400,
      padding: 10
    }

  },
  unselectedCheck: {
    color: '#F9F9F9',
    fontSize: 28,
    
  },
  selectedCheck: {
    color: 'green',
    fontSize: 28,
  },
});

/**
 * Renders a card widget with the provided text description
 *  and the image
 * @param {*} props 
 */
export const ModelTemplateCard = (props) => {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <CardActionArea disabled={props.disabled} onClick={ e => props.handleSelection(props.id)}>
        <CardMedia
          className={classes.media}
          image={props.media || `${process.env.PUBLIC_URL}/assets/images/model-card-blank-image.svg`}
          title="Contemplative Reptile"
        />
        <CardContent className={classes.cardDescription}>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      
      <CardActions className={classes.cardActions}>
        <IconButton color="primary" aria-label="train model" component="span" size="medium">
          { props.ga === true
            ? <CheckCircleOutlineIcon className={ props.selected ? classes.selectedCheck : classes.unselectedCheck } />
            : <LockOutlinedIcon color={'action'}/>
          }
        </IconButton>
      </CardActions>
      </CardActionArea>
    </Card>
  );
}
