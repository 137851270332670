import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { create, update, remove, list } from './apiKeysApi'

const apiKeysAdapter = createEntityAdapter()

const initialState = apiKeysAdapter.getInitialState({
  status: 'idle',
  error: null
})
export const listApiKeys = createAsyncThunk(
  'apiKeys/list',
  async (data) => {
    const response = await list(data.credentials, data.workspaceId)
    return response.data?.results
  }
)

export const addNewApiKey = createAsyncThunk('apiKeys/addNewApiKey', async (data) => {
  const response =  await create(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const updateApiKey = createAsyncThunk('apiKeys/updateApiKey', async (data) => {
  const response =  await update(data.credentials, data.workspaceId, data.payload)
  return response.data?.results
})

export const deleteApiKey = createAsyncThunk('apiKeys/deleteApiKey', async (data) => {
  const response =  await remove(data.credentials, data.workspaceId, data.apiKeyId)
  return response.data?.results?.id
})

const apiKeySlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
  },
  extraReducers: {
    [listApiKeys.pending]: (state, action) => {
      state.status = 'loading';
    },
    [listApiKeys.fulfilled]: (state, action) => { 
      state.status = 'succeeded'
      apiKeysAdapter.upsertMany(state, action.payload)
    },
    [listApiKeys.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewApiKey.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addNewApiKey.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      apiKeysAdapter.addOne(state, action.payload)
    },
    [addNewApiKey.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [updateApiKey.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateApiKey.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      apiKeysAdapter.upsertOne(state, action.payload)
    },
    [updateApiKey.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
    [deleteApiKey.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteApiKey.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      apiKeysAdapter.removeOne(state, action.payload)
    },
    [deleteApiKey.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message;
    },
  }
})

//export const { modelAdded } = modelsSlice.actions;
export default apiKeySlice.reducer;

export const {
  selectAll: selectAllApiKeys,
  selectById: selectApiKeyById,
  selectIds: selectApiKeyIds,
} = apiKeysAdapter.getSelectors(state => state.apiKeys)
