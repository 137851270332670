import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const list = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/actions`, credentials)
  return response
}

export const create = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/actions`, data, credentials)
  return response
}

export const update = async (credentials, workspaceId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/actions/${data.id}`, data, credentials)
  return response
}

