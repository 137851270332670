import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import { selectAllApiKeys } from '../api-keys/apiKeysSlice'
import { ApiKey } from './ApiKey'
import { AddApiKey } from './AddApiKey'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  keyContainer: {
    textAlign: 'start',
  }
}));

export const ApiKeysList = (props) => {
  const classes = useStyles();
  
  const apiKeys = useSelector( state => selectAllApiKeys(state));
  const [showAddApikeyForm, setShowAddApiKeyForm] = useState(false)

  const handleAddApiKey = async () => {
    setShowAddApiKeyForm(true);
  }

  return (

    <Box component='div' className={classes.keyContainer}>
      { apiKeys.map((item, index) =>
        <ApiKey id={item.id} key={item.id} name={item.name} expiresAt={item.expiresAt} digest={item.digest} notificationConfig={props.notificationConfig} setNotificationConfig={props.setNotificationConfig}/>
      )
    }
    
    { showAddApikeyForm ? 
      <AddApiKey setShowAddApiKeyForm={setShowAddApiKeyForm}  notificationConfig={props.notificationConfig} setNotificationConfig={props.setNotificationConfig}/> :
      <Button variant='outlined' color='primary' size='small' onClick={handleAddApiKey}>Generate New API Key</Button>
    }
    </Box>
  )
}