import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  blockingErrorNotice: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,//'#efc0c0',
    color: 'white',
    margin: 15,
    borderRadius: 3,
  },
  blockingInfoNotice: {
    width: '100%',
    backgroundColor: 'green',//'#efc0c0',
    color: 'white',
    margin: 15,
    borderRadius: 3,
  }
}));
export const BlockingErrorNotice = (props) => {
  const classes = useStyles();
  return (
    <div variant="outlined" className={props.info ? classes.blockingInfoNotice : classes.blockingErrorNotice }>
      <Typography component="h1" variant="subtitle1" align="center">
        {props.message}
      </Typography>
    </div>

  )
}