import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '30%',
    marginBottom: 30,
  },
  searchBar: {
   display: 'grid',
  },
  actionTemplate: {
    minHeight: theme.spacing(17),
  },
  square: {
    objectFit: 'contain',
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  }
}))

export const ActionTemplates = (props) => {
  const classes = useStyles();
  
  const updateNode = (actionTemplate) => {
    let _nodes = [...(props.nodes)]
    _nodes[props.selectedNode].actionId = actionTemplate.id;
    _nodes[props.selectedNode].stepNumber = props.nodes.length;
    _nodes[props.selectedNode].actionName = actionTemplate.name;
    _nodes[props.selectedNode].actionDescription = actionTemplate.description;
    _nodes[props.selectedNode].condition = [];
    _nodes[props.selectedNode].input = actionTemplate.input;
    _nodes[props.selectedNode].output = actionTemplate.output;
    _nodes[props.selectedNode].connectionRef = actionTemplate.connectionRef;
    props.setNodes(_nodes); 
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <div>
        <Paper component="form" classes={{ root: classes.searchBar}} elevation={0}>
          <TextField
            className={classes.input}
            placeholder='Search for action templates'
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Paper>
      
      </div>

      </Grid>
      {
        props.actionTemplates.map((item, index) =>
        <Grid item xs={12} md={4} key={item.id}>
        <Card variant="outlined" className={classes.actionTemplate}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Avatar alt={item.description} variant="square" className={classes.square} src={`https://build.hydra.ai/assets/images/${item.logo}`}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography className={classes.title} color="textSecondary" style={{textAlign: 'left'}} gutterBottom>
                {item.description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{justifyContent: 'flex-end'}}>
          <Button size="small" onClick={() => updateNode(item)}>Select</Button>
        </CardActions>
      </Card>
      </Grid>
        )

      }
    </Grid>
  )

}
