export const errors = {
  LOGIN_FAILED: 'Login failed',
  PASSWORD_RESET_FAILED: 'Password reset failed',
  PASSWORD_RESET_SENT: 'Check your email for the password reset link',
  SIGNUP_FAILED: 'Signup failed',
  ACCOUNT_UPDATE_FAILED: 'Account update failed',
}


//'වැරදි මුරපදයක් හෝ පරිශීලක නාමයක්'
//That was bad. I guess.'
//'すべてのデバイスからサインアウト'