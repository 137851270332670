import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: 30,
  },
  editorPaper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    //backgroundColor: '#FCFCFC',
    minWidth: 600,
    minHeight: '80vh',
  },
  modelSummaryText: {
    marginBottom: 20,
  },
  codeBlock: {
    fontFamily: 'monospace',
    borderRadius: 5,
    backgroundColor: '#EFEFEF',
    fontSize: '1.2em',
    marginTop: 15,
    marginBottom: 15,
    '& div': {
      padding: theme.spacing(2),
    }
  },
}))

export const ModelSummary = (props) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={ classes.editorPaper }>
      { props.modelSource === 'api' &&
        <Box component='span'>
          <Typography className={classes.modelSummaryText}>See below for your model endpoint url. Checkout the <a href='https://docs.hydra.ai'>docs</a> for more details on how to use this endpoint.</Typography>
          <Box className={classes.codeBlock}>
            <Box>https://api.hydra.ai/v1/workspaces/{props.workspaceId}/models/{props.modelId}</Box>
          </Box>
          <br/>
          <Typography className={classes.modelSummaryText}>Your API host is https://api.hydra.ai/v1</Typography>
          <Typography className={classes.modelSummaryText}>Your workspace ID is {props.workspaceId}</Typography>
          <Typography className={classes.modelSummaryText}>Your model ID is {props.modelId}</Typography>
          <Typography className={classes.modelSummaryText}>Your API key is available on the settings screen</Typography>
        </Box>
      }
      <Typography className={classes.modelSummaryText}>{props.summaryText}</Typography> 
    </Paper>
  )
}