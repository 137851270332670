import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '60%',
    marginBottom: 30,
  },
  editorPaper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    backgroundColor: '#FCFCFC',
  },
  actionButton: {
    marginTop: 20,
    marginRight: 10,
  }
}))

export const AutomationSelectorParamMap = (props) => {
  const classes = useStyles();
  
  //TODO match the updated hash keys in the payload
  const handleVarFieldChange = (name, value, label, index) => {
    const _varFields = [...props.varFields]
    _varFields[index] = { name: name, label: label, value: value, valueKey: props.paramOptions[value]}
    props.setVarFields(_varFields)
  };

  const clearData = (event) => {
    let _varFields = [...props.varFields]
    _varFields = _varFields.map( v => ({ name: v.name, value: '' }) )
    props.setVarFields(_varFields)
  }
  return (
    <Box component={'span'}>
      <CssBaseline />
      <Paper className={classes.editorPaper} elevation={0} variant='outlined'>
      <Typography className={classes.configSectionLabel} variant='overline'>
        Automation Variables
      </Typography>
      <br/>
      { props.varFields.map((item, index) => 
        <Autocomplete
        id={`${index}`}
        onInputChange={ (_e, v, _r) => {
          handleVarFieldChange(item.name, v, item.label, index)
        } }
        key={`auto-complete-key-${index}`}
        name={item.name}
        value={item.value}
        classes={{root: classes.root}}
        freeSolo
        options={ Object.keys(props.paramOptions) || [] }
        renderInput={(params) => (
          <TextField {...params} 
            label={item.label}
            margin="normal"
             />
        )}
      />
      )}

      { props.showButtons
        ? <Box>
            <Button variant="outlined" className={classes.actionButton} onClick={ clearData }>Clear data</Button>
            <Button variant="outlined" className={classes.actionButton} color="primary">Test Automation</Button>
          </Box>
        : ''
      }
      </Paper>
    </Box>
  )
}