import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';
import PlayArrowTwoToneIcon from '@material-ui/icons/PlayArrowTwoTone';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import Chip from '@material-ui/core/Chip';
import GrainTwoToneIcon from '@material-ui/icons/GrainTwoTone';
import { updateModel, trainModel } from './modelsSlice'

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    minWidth: 300,
    height: 250,
    margin: '10px',
    borderColor: '#e0e0e0',

  },
  media: {
    height: 80,
    backgroundSize: 'fill',
  },
  cardActions: {
    display: 'flow-root',
  },
  cardDescription: {
    height: 100,
    minHeight: 100,
    overflow: 'hidden',
    '& p': {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textAlign: 'left',
      color: 'black',
      fontWeight: 400,
      padding: 10
    }

  },
  actionButton: {
    //float: 'right',
  },
  onQueue: {
    color: '#fb8c00',
  },
  offQueue: {
    color: 'primary',
  }
});

/**
 * Renders a card widget with the provided text description
 *  and the image
 * @param {*} props 
 */
export const ModelCard = (props) => {
  const classes = useStyles()
  const history = useHistory();

  const modelStatus = useSelector((state) => state.models.status)
  const dispatch = useDispatch();

  const onTurnOnClicked = async (workspaceId, modelId) => {
    const newStatus = (props.status === 1) ? 0 : 1;
    const payload = { id: modelId, status: newStatus };
    await patchModel(workspaceId, payload);
    props.setNotificationConfig({ show: true, notice: (newStatus === 1 ? 'Model is on' : 'Model is off'), severity: 'success' })
  }

  const onTrainClicked = async (workspaceId, modelId) => {
    if (props?.trainConfig?.status === 0) {
      const _trainConfig = { ...props.trainConfig };
      _trainConfig.status = 1;
      const payload = { id: modelId, trainConfig: _trainConfig };
      await queueTrainModel(workspaceId, payload);
      props.setNotificationConfig({ show: true, notice: 'Queued for training', severity: 'success' })

    } else {
      props.setNotificationConfig({ show: true, notice: 'Allready in queue', severity: 'info' })
    }
  }

  const patchModel = async (_workspaceId, payload) => {
    if (modelStatus != 'loading') {
      try {
        const modelUpdate = await dispatch(updateModel({
          credentials: props.session.authHeader,
          workspaceId: props.session.workspaceId,
          payload: payload,
        }))
        unwrapResult(modelUpdate)
      } catch (e) {
        console.log(e);
        props.setNotificationConfig({ show: true, notice: e, severity: 'error' })
      }
    }
  }

  const queueTrainModel = async (_workspaceId, payload) => {
    if (modelStatus != 'loading') {
      try {
        const modelUpdate = await dispatch(trainModel({
          credentials: props.session.authHeader,
          workspaceId: props.session.workspaceId,
          payload: payload,
        }))
        unwrapResult(modelUpdate)
      } catch (e) {
        console.log(e);
        props.setNotificationConfig({ show: true, notice: e, severity: 'error' })
      }
    }
  }

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <CardMedia
        className={classes.media}
        image={props.media || `${process.env.PUBLIC_URL}/assets/images/model-card-blank-image.svg`}
      />
      <CardContent className={classes.cardDescription}>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.modelName}
        </Typography>
        <Chip label={((props.score || 0) * 100).toFixed(2)} size='small' variant='outlined' style={{ float: 'left', marginLeft: 10}} color='primary'></Chip>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <Tooltip title="Preview predictions" aria-label="preview predictions">
          <IconButton onClick={(e) => history.push(`/workspaces/${props.workspaceId}/models/${props.id}/predictions`)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.actionButton}>
            <PlayArrowTwoToneIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Activate Model" aria-label="activate model">
          <IconButton onClick={ (_e) => onTurnOnClicked(props.workspaceId, props.id)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.actionButton}>
            {props.status === 1
              ? <ToggleOnTwoToneIcon color={'primary'} />
              : <ToggleOffTwoToneIcon color={'action'} />
            }
          </IconButton>
        </Tooltip>
        <Tooltip title="Queue for training" aria-label="queue for training">
          <IconButton onClick={(e) => onTrainClicked(props.workspaceId, props.id)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.actionButton}>
            <GrainTwoToneIcon className={props.trainConfig?.status === 0 ? classes.offQueue : classes.onQueue}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload training data file" aria-label="upload trainind data file">
          <IconButton onClick={(e) => history.push(`/workspaces/${props.workspaceId}/models/${props.id}/data`)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.actionButton}>
            <CloudUploadTwoToneIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Model" aria-label="edit model">
          <IconButton onClick={(e) => history.push(`/workspaces/${props.workspaceId}/models/${props.id}`)} color="primary" aria-label="edit model" component="span" size="medium" className={classes.actionButton}>
            <EditTwoToneIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
