import axios from 'axios';
import { HYDRA_API } from '../../configs/env'

export const login = async (credentials) => {
  const response = await axios.post(`${HYDRA_API}/users/login`, credentials)
  return response
}

export const logout = async (credentials) => {
  const response = await axios.delete(`${HYDRA_API}/users/logout`, credentials)
  return response
}

export const signup = async (data) => {
  const response = await axios.post(`${HYDRA_API}/users/signup`, data)
  return response
}

export const show = async (credentials, userId) => {
  const response = await axios.get(`${HYDRA_API}/users/${userId}`, credentials)
  return response
}

export const listWorkspaceUsers = async (credentials, workspaceId) => {
  const response = await axios.get(`${HYDRA_API}/workspaces/${workspaceId}/users`, credentials)
  return response
}

export const updateWorkspaceUser = async (credentials, workspaceId, userId, data) => {
  const response = await axios.put(`${HYDRA_API}/workspaces/${workspaceId}/users/${userId}`, data, credentials)
  return response
}

export const changePassword = async (data) => {
  const response = await axios.put(`${HYDRA_API}/users/change_password`, data)
  return response
}

export const resetPassword = async (data) => {
  const response = await axios.put(`${HYDRA_API}/users/process_password_reset`, data)
  return response
}

export const generatePasswordReset = async (data) => {
  const response = await axios.put(`${HYDRA_API}/users/reset_password`, data)
  return response
}
export const inviteWorkspaceUser = async (credentials, workspaceId, data) => {
  const response = await axios.post(`${HYDRA_API}/workspaces/${workspaceId}/users/`, data, credentials)
  return response
}