import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  ruleFormControl: {
    marginBottom: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ruleSelectorBox: {
    width: 400,
    minWidth: 400,
    marginTop: 15,
    paddingBottom: 10,
    textAlign: 'start',
  },

}));

/**
 * Action data rule component
 * 
 * @param {*} props 
 */
export const ActionDataRule = (props) => {
  const classes = useStyles();
  
  const handleChange = (event) => {
    props.updateRunCondition({rule: event.target.value})
  };

  return (
      <FormControl className={classes.ruleFormControl}>
      
        <InputLabel id={`${props.fieldName}-rule-matcher`}>Condition</InputLabel>
        <Select
          id={`${props.fieldName}-rule-matcher-selector`}
          value={props.runCondition?.[0]?.rule || ''}
          onChange={handleChange}
          className={classes.ruleCondtionSelector}
        >
          <MenuItem value='text_starts_with'>Starts with</MenuItem>
          <MenuItem value='text_ends_with'>Ends with</MenuItem>
          <MenuItem value='text_contains'>Contains</MenuItem>
          <MenuItem value='text_matches'>Matches</MenuItem>
          <MenuItem value='text_regex'>Regex</MenuItem>
        </Select>
        
      </FormControl>
   
  );
}
